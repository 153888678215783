import {Reducer} from "redux";
import {IListingState} from "../../types/types";
import {
    APPROVED_LISTING,
    APPROVING_LISTING,
    DELETED_LISTING,
    DELETING_LISTING,
    CHANGING_LISTING_BOOK,
    CHANGED_LISTING_BOOK,
    GETTING_LISTING,
    GETTING_LISTINGS,
    GOT_LISTING,
    GOT_LISTINGS,
    IListingsActions,
    REJECTING_LISTING
} from "../../actions/listing/actions";

const initialListingState: IListingState = {
    listingViews: [],
    count: 0,
    loading: false,
};

// @ts-ignore
export const listingsReducer: Reducer<IListingState, IListingsActions> = (
    state = initialListingState,
    action
) => {
    switch (action.type) {
        case GETTING_LISTINGS: {
            return {
                ...state,
                loading: true
            };
        }
        case GOT_LISTINGS: {
            return {
                ...state,
                listingViews: action.payload.listingViews,
                count: action.payload.count,
                loading: false
            };
        }
        case GETTING_LISTING: {
            return {
                ...state,
                loading: true
            };
        }

        case GOT_LISTING: {
            return {
                ...state,
                loading: false
            };
        }
        case APPROVING_LISTING: {
            return {
                ...state,
                loading: true
            }
        }
        case APPROVED_LISTING: {
            return {
                ...state,
                loading: false
            }
        }
        case REJECTING_LISTING: {
            return {
                ...state,
                loading: true
            }
        }
        case DELETING_LISTING: {
            return {
                ...state,
                loading: false
            }
        }
        case DELETED_LISTING: {
            return {
                ...state,
                loading: false
            }
        }
        case CHANGING_LISTING_BOOK: {
            return {
                ...state,
                loading: true
            }
        }
        case CHANGED_LISTING_BOOK: {
            return {
                ...state,
                loading: false
            }
        }
        default:
            return state;
    }
};

