import {FeedbackFilter, FeedbacksPage, IFeedbackDetails, ReportsPage} from '../../types/types'
import {ThunkAction} from "redux-thunk";
import api from '../api'
import {ActionCreator, Dispatch} from "redux";

export const GETTING_FEEDBACKS = "GETTING_FEEDBACKS";
export const GOT_FEEDBACKS = "GOT_FEEDBACKS";

export interface IGettingFeedbacksAction {
    type: typeof GETTING_FEEDBACKS
}

export interface IGotFeedbacksAction {
    type: typeof GOT_FEEDBACKS
    payload: {
        feedbacks: IFeedbackDetails[]
        count: number
    }
}


export const getFeedbacks: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IGotFeedbacksAction>,
    // The type for the data within the last action
    ReportsPage[],
    // The type of the parameter for the nested function
    FeedbackFilter,
    // The type of the last action to be dispatched
    IGettingFeedbacksAction>> = (filter: FeedbackFilter) => {
    return async (dispatch: Dispatch) => {
        const gettingFeedbacksAction: IGettingFeedbacksAction = {
            type: GETTING_FEEDBACKS
        };
        let params = `size=${filter.size}&direction=${filter.direction}`
        if (filter.lastSeenId !== null) {
            params = params + `&lastSeenId=${filter.lastSeenId}`
        }
        dispatch(gettingFeedbacksAction);
        const axiosResponse = await api.get<FeedbacksPage>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/feedback/list?${params}`)
        const gotFeedbacksAction: IGotFeedbacksAction = {
            payload: {
                feedbacks: axiosResponse.data.feedbacks,
                count: axiosResponse.data.count
            },
            type: GOT_FEEDBACKS
        };
        return dispatch(gotFeedbacksAction);
    };
};

export type IFeedbacksActions =
    | IGettingFeedbacksAction
    | IGotFeedbacksAction;
