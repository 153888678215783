import {IUserProfile, UserFilter, UsersPage} from '../../types/types'
import {ThunkAction} from "redux-thunk";
import api from '../api'
import {ActionCreator, Dispatch} from "redux";
import {stringify} from "querystring";

export const GETTING_USERS = "GETTING_USERS";
export const GOT_USERS = "GOT_USERS";
export const GETTING_USER = "GETTING_USER";
export const GOT_USER = "GOT_USER";
export const UPDATING_DISABLE_FLAG = "UPDATING_DISABLE_FLAG";
export const UPDATED_DISABLE_FLAG = "UPDATED_DISABLE_FLAG";

export interface IGettingUsersAction {
    type: typeof GETTING_USERS
}

export interface IGotUsersAction {
    type: typeof GOT_USERS
    payload: {
        users: IUserProfile[]
        count: number
    }
}

export interface IGettingUserAction {
    type: typeof GETTING_USER
}

export interface IGotUserAction {
    type: typeof GOT_USER
    payload: {
        user: IUserProfile
    }
}

export interface IUpdatingDisableFlag {
    type: typeof UPDATING_DISABLE_FLAG
}

export interface IUpdatedDisableFlag {
    type: typeof UPDATED_DISABLE_FLAG
}

export const getUsers: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IGotUsersAction>,
    // The type for the data within the last action
    UsersPage,
    // The type of the parameter for the nested function
    UserFilter,
    // The type of the last action to be dispatched
    IGotUsersAction>> = (userFilter: UserFilter) => {
    return async (dispatch: Dispatch) => {
        const gettingUsersAction: IGettingUsersAction = {
            type: GETTING_USERS
        };
        let params = `size=${userFilter.size}&direction=${userFilter.direction}`
        if (userFilter.lastSeenId !== null) {
            params = params + `&lastSeenId=${userFilter.lastSeenId}`
        }
        if (userFilter.term !== null) {
            params = params + `&term=${userFilter.term}`
        }
        dispatch(gettingUsersAction);
        const axiosResponse = await api.get<UsersPage>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/user/list?${params}`)
        const gotUsersAction: IGotUsersAction = {
            payload: {
                users: axiosResponse.data.users,
                count: axiosResponse.data.count
            },
            type: GOT_USERS
        };
        return dispatch(gotUsersAction);
    };
};

export const getUser: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IGotUserAction>,
    // The type for the data within the last action
    IUserProfile,
    // The type of the parameter for the nested function
    number,
    // The type of the last action to be dispatched
    IGotUserAction>> = (userId: number) => {
    return async (dispatch: Dispatch) => {
        const gettingUserAction: IGettingUserAction = {
            type: GETTING_USER
        };
        dispatch(gettingUserAction);
        const axiosResponse = await api.get<IUserProfile>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/user/${userId}`)
        const gotUserAction: IGotUserAction = {
            payload: {
                user: axiosResponse.data
            },
            type: GOT_USER
        };
        return dispatch(gotUserAction);
    };
};

export const updateDisableFlag: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IUpdatedDisableFlag>,
    // The type for the data within the last action
    IUserProfile,
    // The type of the parameter for the nested function
    number,
    // The type of the last action to be dispatched
    IUpdatedDisableFlag>> = (userId: number, disabled: boolean) => {
    return async (dispatch: Dispatch) => {
        const body = {
            userId: userId,
            disabled: disabled,
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const updatingDisableFlag: IUpdatingDisableFlag = {
            type: UPDATING_DISABLE_FLAG
        };
        dispatch(updatingDisableFlag);
        await api.post<IUserProfile>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/user/disable/${userId}`, stringify(body), config)
        const updatedDisableFlag: IUpdatedDisableFlag = {
            type: UPDATED_DISABLE_FLAG
        };
        return dispatch(updatedDisableFlag);
    };
};

export type IUsersActions =
    | IGettingUsersAction
    | IGotUsersAction
    | IGettingUserAction
    | IGotUserAction
    | IUpdatingDisableFlag
    | IUpdatedDisableFlag;
