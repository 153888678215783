import React, {FC, useState} from "react";
import {Link, RouteProps} from "react-router-dom";
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from "mui-datatables";
import {Avatar, IconButton, Theme, Typography} from "@material-ui/core";
import {IAppState, IUserProfile, SearchDirection, UserFilter} from "../../types/types";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {IAuthorActions} from "../../actions/author/actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import {makeStyles} from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from '@material-ui/icons/Done';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {getUsers, IGotUsersAction} from "../../actions/user/actions";


interface IProps extends RouteProps {
    getUsers: (filter: UserFilter) => Promise<IGotUsersAction>
    loading: boolean;
}


const useStyles = makeStyles((theme: Theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    }
}));

const UsersTable: FC<IProps> = ({
                                    getUsers,
                                    loading
                                }) => {
    const classes = useStyles()
    const defaultAvatar = process.env.PUBLIC_URL + '/default-account-avatar.svg';
    const [users, setUsers] = useState<IUserProfile[]>([])
    const [count, setCount] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [page, setPage] = useState<number>(0)
    const [, setDirection] = useState<SearchDirection>(SearchDirection.NEXT)
    const [term, setTerm] = useState<string | null>(null)

    const search = (newPage: number, rowsNumber: number, searchTerm: string | null, users: Array<IUserProfile>) => {
        let filter = calculateFilter(newPage, rowsNumber, searchTerm, users)
        setDirection(filter.direction);
        setPage(newPage);
        setRowsPerPage(rowsNumber);
        getUsers(filter).then(r => {
            setUsers(r.payload.users)
            setCount(r.payload.count)
        }).catch(e => console.log(e))
    }

    const calculateFilter = (newPage: number, rowsNumber: number, searchTerm: string | null, users: Array<IUserProfile>): UserFilter => {
        if (rowsPerPage !== rowsNumber || term !== searchTerm) {
            return {
                lastSeenId: null,
                term: searchTerm,
                size: rowsNumber,
                direction: SearchDirection.NEXT
            }
        }

        let direction: SearchDirection
        let lastSeenId: number | null = null
        if (rowsNumber === rowsPerPage) {
            if (page <= newPage) {
                direction = SearchDirection.NEXT
                lastSeenId = getLastSeenId(SearchDirection.NEXT, users)
            } else {
                direction = SearchDirection.PREVIOUS
                lastSeenId = getLastSeenId(SearchDirection.PREVIOUS, users)
            }
        } else {
            direction = SearchDirection.NEXT
        }

        return {
            lastSeenId: lastSeenId,
            term: searchTerm,
            size: rowsNumber,
            direction: direction
        }
    }

    const getLastSeenId = (direction: SearchDirection, users: Array<IUserProfile>): number | null => {
        if (users.length === 0) {
            return null
        }
        if (direction === SearchDirection.NEXT) {
            return users[users.length - 1].id
        } else {
            return users[0].id
        }
    }

    const handleRowsPerPageUpdate = (rowsNumber: number) => {
        setRowsPerPage(rowsNumber);
        search(0, rowsNumber, term, users);
    }

    const handleSearchTerm = (searchText: string) => {
        setTerm(searchText)
        search(page, rowsPerPage, searchText, users)
    }

    const options: MUIDataTableOptions = {
        filter: true,
        fixedSelectColumn: false,
        selectableRows: 'none',
        viewColumns: false,
        responsive: "vertical",
        serverSide: true,
        count: count,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 50, 100],
        download: false,
        print: false,
        onChangePage: currentPage => search(currentPage, rowsPerPage, term, users),
        onChangeRowsPerPage: rowsNumber => handleRowsPerPageUpdate(rowsNumber),
        onTableInit: () => search(0, rowsPerPage, term, users),
        onSearchChange: searchText => handleSearchTerm(searchText),
    };
    const columns: MUIDataTableColumnDef[] = [
        {
            name: "avatarUrl",
            label: "Аватар",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                    const user = users[dataIndex]
                    if (user.avatarUrl) {
                        return <Avatar
                            className={classes.small}
                            alt={`Avatar${user.id}`}
                            src={`${user.avatarUrl}`}
                        />
                    } else {
                        return <Avatar
                            className={classes.small}
                            alt={`Avatar${user.id}`}
                            src={`${defaultAvatar}`}
                        />
                    }
                }
            },
        },
        {
            name: "email",
            label: "Имейл",
            options: {
                filter: false,
                sort: false
            },
        },
        {
            name: "nickname",
            label: "Никнейм",
            options: {
                filter: false,
                sort: false
            },
        },
        {
            name: "listingsCount",
            label: "Брой обяви",
            options: {
                filter: false,
                sort: false
            },
        },
        {
            name: "emailVerified",
            label: "Верифициран имейл",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                    if (users[dataIndex].emailVerified) {
                        return <IconButton
                            size={"small"}
                        >
                            <DoneIcon/>
                        </IconButton>
                    } else {
                        return <IconButton
                            size={"small"}
                        >
                            <HighlightOffIcon/>
                        </IconButton>
                    }
                }
            }
        },
        {
            name: "Редактирай",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                    return <IconButton
                        size={"small"}
                        component={(props) => (<Link {...props} to={`/admin/user/${users[dataIndex].id}`}/>)}
                    >
                        <EditIcon/>
                    </IconButton>

                }
            }
        }
    ]

    return (
        <div>
            <MUIDataTable
                title={
                    <Typography variant="h6">
                        Потребители
                        {loading && <LinearProgress color="secondary"/>}
                    </Typography>
                }
                data={users}
                columns={columns}
                options={options}
            />
        </div>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        users: store.usersState.users,
        count: store.usersState.count,
        loading: store.usersState.loading
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IAuthorActions>) => {
    return {
        getUsers: (filter: UserFilter) => dispatch(getUsers(filter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
