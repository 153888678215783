import {Reducer} from "redux";
import {IBookState} from "../../types/types";
import {
    APPROVED_BOOK,
    APPROVING_BOOK,
    DELETED_BOOK,
    DELETING_BOOK,
    GETTING_BOOK,
    GETTING_BOOKS,
    GOT_BOOK,
    GOT_BOOKS,
    IBooksActions,
    SAVED_BOOK,
    SAVING_BOOK
} from "../../actions/book/actions";

const initialBooksState: IBookState = {
    books: [],
    count: 0,
    loading: false
};

// @ts-ignore
export const booksReducer: Reducer<IBookState, IBooksActions> = (
    state = initialBooksState,
    action
) => {

    switch (action.type) {
        case GETTING_BOOKS: {
            return {
                ...state,
                loading: true
            };
        }
        case GOT_BOOKS: {
            return {
                ...state,
                books: action.payload.books,
                count: action.payload.count,
                loading: false
            };
        }
        case SAVING_BOOK: {
            return {
                ...state,
                loading: true
            };
        }

        case SAVED_BOOK: {
            return {
                ...state,
                loading: false
            };
        }
        case GETTING_BOOK: {
            return {
                ...state,
                loading: true
            };
        }
        case GOT_BOOK: {
            return {
                ...state,
                loading: false
            };
        }
        case APPROVING_BOOK: {
            return {
                ...state,
                loading: true
            };
        }
        case APPROVED_BOOK: {
            return {
                ...state,
                loading: false
            };
        }
        case DELETING_BOOK: {
            return {
                ...state,
                loading: true
            };
        }
        case DELETED_BOOK: {
            return {
                ...state,
                loading: false
            };
        }
        default:
           return state;
    }

};

