import React from 'react'
import {Redirect, Route, RouteProps} from 'react-router-dom'
import {connect} from 'react-redux'
import {IAppState} from "../types/types";

interface PrivateRouteProps extends RouteProps {
    component: any;
    authenticated: boolean;
    rest?: any
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({component: Component, authenticated, ...rest}: any) => (
    <Route
        {...rest}
        render={(props) => authenticated ? <Component {...props} /> : <Redirect to='/admin/login'/>}/>
);
const mapStateToProps = (state: IAppState) => ({
    authenticated: state.authState.authenticated
});
export default connect(mapStateToProps)(PrivateRoute)
