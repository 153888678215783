import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";
import logger from 'redux-logger';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {booksReducer} from '../reducers/book/reducer'
import {authReducer} from '../reducers/auth/reducer'
import {genresReducer} from '../reducers/genre/reducer'
import {authorsReducer} from '../reducers/author/reducer'
import {IAppState} from "../types/types";
import {listingsReducer} from "../reducers/listing/reducer";
import {reportsReducer} from "../reducers/report/reducer";
import {feedbacksReducer} from "../reducers/feedback/reducer";
import {usersReducer} from "../reducers/user/reducer";

const composeEnhancer: typeof compose =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const reducer = combineReducers<IAppState>({
    booksState: booksReducer,
    authState: authReducer,
    genresState: genresReducer,
    authorsState: authorsReducer,
    listingsState: listingsReducer,
    reportsState: reportsReducer,
    feedbacksState: feedbacksReducer,
    usersState: usersReducer
})

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['authState']
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(thunk, logger)));
export const persistor = persistStore(store);

