import React from 'react'
import {Redirect, Route, RouteProps} from 'react-router-dom'
import {connect} from 'react-redux'
import {IAppState} from "../types/types";

interface PublicRouteProps extends RouteProps {
    component: any;
    authenticated: boolean;
    rest?: any
}

const PublicRoute: React.FC<PublicRouteProps> = ({component: Component, authenticated, ...rest}) => (
    <Route
        {...rest}
        render={(props) => authenticated ? <Redirect to='/admin' {...props}/> : <Component {...props}/>}
    />
);
const mapStateToProps = (state: IAppState) => ({
    authenticated: state.authState.authenticated
});
export default connect(mapStateToProps)(PublicRoute)
