import React, {FC, useEffect, useState} from "react";
import {match, RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import {Button, Grid, TextField} from "@material-ui/core";
import {IAppState, IAuthor} from "../../types/types";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {
    getAuthor,
    IAuthorActions,
    IGotAuthorAction,
    ISavedAuthorAction,
    saveAuthor
} from "../../actions/author/actions";
import LinearProgress from "@material-ui/core/LinearProgress";


interface AuthorEditorParams {
    id: string;
}

interface IProps extends RouteComponentProps<AuthorEditorParams> {
    getAuthor: (id: number) => Promise<IGotAuthorAction>;
    saveAuthor: (author: IAuthor) => Promise<ISavedAuthorAction>;
    match: match<AuthorEditorParams>;
    loading: boolean;
}

const AuthorEditor: FC<IProps> = ({
                                      getAuthor,
                                      saveAuthor,
                                      match,
                                      loading
                                  }) => {

    const history = useHistory();
    const [id, setId] = useState<number | null>(null)
    const [name, setName] = useState<string>("")
    const [createdAt, setCreatedAt] = useState<number>(0)
    const [updatedAt, setUpdatedAt] = useState<number>(0)
    const [approved, setApproved] = useState<boolean>(false)

    useEffect(() => {
        if (match.params.id !== "new") {
            getAuthor(+match.params.id).then((value => {
                setId(value.payload.author.id)
                setName(value.payload.author.name)
                setApproved(value.payload.author.isApproved)
                setCreatedAt(value.payload.author.createdAt)
                setUpdatedAt(value.payload.author.updatedAt)
            }))
        }
    }, [getAuthor, match.params.id, match.path]);

    const handleSubmit = () => {
        saveAuthor({
            id: id,
            name: name,
            isApproved: approved,
            books: null,
            createdAt: createdAt,
            updatedAt: updatedAt
        })
            .then(() => {
                history.push("/admin/author")
            })
            .catch((error) => {
                console.log("Error while saving author", error);
            })
    };

    return (
        <Grid container spacing={4}>
            {(loading) && <LinearProgress color="secondary"/>}
            <Grid item xs={12}>
                <TextField
                    id="name"
                    label="Име"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    variant="outlined"
                />
            </Grid>

            <Grid item xs={12} container direction="row" justify="flex-end" alignItems={"flex-end"}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                >
                    Запази
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.goBack()}
                >
                    Назад
                </Button>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        loading: store.authorsState.loading,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IAuthorActions>) => {
    return {
        getAuthor: (id: number) => dispatch(getAuthor(id)),
        saveAuthor: (author: IAuthor) => dispatch(saveAuthor(author))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthorEditor));
