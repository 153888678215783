import React, {FC, useEffect, useState} from "react";
import {Link, RouteProps} from "react-router-dom";
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from "mui-datatables";
import {Fab, IconButton, Typography} from "@material-ui/core";
import {AuthorFilter, IAppState, IAuthor, SearchDirection} from "../../types/types";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {
    approveAuthor,
    getAuthors,
    IApprovedAuthor,
    IAuthorActions,
    IGotAuthorsAction
} from "../../actions/author/actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddIcon from "@material-ui/icons/Add";
import {makeStyles} from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from '@material-ui/icons/Done';
import useDebounce from "../../utils/functions";


interface IProps extends RouteProps {
    getAuthors: (filter: AuthorFilter) => Promise<IGotAuthorsAction>
    approveAuthor: (id: number) => Promise<IApprovedAuthor>
    loading: boolean;
}


const useStyles = makeStyles(() => ({
    fabSecondary: {
        position: 'absolute',
        bottom: 18,
        right: 18,
    }
}));

const AuthorsTable: FC<IProps> = ({
                                      getAuthors,
                                      approveAuthor,
                                      loading
                                  }) => {
    const classes = useStyles()
    const [authors, setAuthors] = useState<IAuthor[]>([])
    const [count, setCount] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [page, setPage] = useState<number>(0)
    const [, setDirection] = useState<SearchDirection>(SearchDirection.NEXT)
    const [term, setTerm] = useState<string | null>(null)
    const debouncedSearchTerm = useDebounce(term, 800)
    const [approvedFilter, setApprovedFilter] = useState<boolean | null>(null)

    useEffect(() => {
        if(debouncedSearchTerm) {
            search(page, rowsPerPage, debouncedSearchTerm, approvedFilter, authors)
        }
    }, [debouncedSearchTerm]);

    const search = (newPage: number, rowsNumber: number, searchTerm: string | null, approved: boolean | null, authors: Array<IAuthor>) => {
        let filter = calculateFilter(newPage, rowsNumber, searchTerm, approved, authors)
        setDirection(filter.direction);
        setPage(newPage);
        setRowsPerPage(rowsNumber);
        getAuthors(filter).then(r => {
            setAuthors(r.payload.authors)
            setCount(r.payload.count)
        }).catch(e => console.log(e))
    }

    const calculateFilter = (newPage: number, rowsNumber: number, searchTerm: string | null, approved: boolean | null, authors: Array<IAuthor>): AuthorFilter => {
        if (rowsPerPage !== rowsNumber || approvedFilter !== approved || term !== searchTerm) {
            return {
                lastSeenId: null,
                approved: approved,
                term: searchTerm,
                size: rowsNumber,
                direction: SearchDirection.NEXT
            }
        }

        let direction: SearchDirection
        let lastSeenId: number | null = null
        if (rowsNumber === rowsPerPage) {
            if (page <= newPage) {
                direction = SearchDirection.NEXT
                lastSeenId = getLastSeenId(SearchDirection.NEXT, authors)
            } else {
                direction = SearchDirection.PREVIOUS
                lastSeenId = getLastSeenId(SearchDirection.PREVIOUS, authors)
            }
        } else {
            direction = SearchDirection.NEXT
        }

        return {
            lastSeenId: lastSeenId,
            approved: approved,
            term: searchTerm,
            size: rowsNumber,
            direction: direction
        }
    }

    const getLastSeenId = (direction: SearchDirection, authors: Array<IAuthor>): number | null => {
        if (authors.length === 0) {
            return null
        }
        if (direction === SearchDirection.NEXT) {
            return authors[authors.length - 1].id
        } else {
            return authors[0].id
        }
    }

    const handleRowsPerPageUpdate = (rowsNumber: number) => {
        setRowsPerPage(rowsNumber);
        search(0, rowsNumber, term, approvedFilter, authors);
    }

    const handleSearchTerm = (searchText: string) => {
        setTerm(searchText)
    }

    const handleApprove = (id: number) => {
        approveAuthor(id)
            .then(() => {
                search(page, rowsPerPage, term, approvedFilter, [])
            })
            .catch(e => console.log(e))
    }


    const applyFilters = (changedColumn: string, filterList: any[]) => {
        if (filterList[1][0]) {
            if (filterList[1][0] === "Да") {
                setApprovedFilter(true)
                search(page, rowsPerPage, term, true, authors)
            } else if (filterList[1][0] === "Не") {
                search(page, rowsPerPage, term, false, authors)
            } else {
                setApprovedFilter(null)
                search(page, rowsPerPage, term, null, authors)
            }
        } else {
            setApprovedFilter(null)
            search(page, rowsPerPage, term, null, authors)
        }
    }

    const options: MUIDataTableOptions = {
        filter: true,
        fixedSelectColumn: false,
        selectableRows: 'none',
        viewColumns: false,
        responsive: "vertical",
        serverSide: true,
        count: count,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 50, 100],
        download: false,
        print: false,
        onChangePage: currentPage => search(currentPage, rowsPerPage, term, approvedFilter, authors),
        onChangeRowsPerPage: rowsNumber => handleRowsPerPageUpdate(rowsNumber),
        onTableInit: () => search(0, rowsPerPage, term, approvedFilter, authors),
        onSearchChange: searchText => handleSearchTerm(searchText),
        onFilterChange: (changedColumn: string, filterList: any[]) => applyFilters(changedColumn, filterList)
    };
    const columns: MUIDataTableColumnDef[] = [
        {
            name: "name",
            label: "Име",
            options: {
                filter: false,
                sort: false
            },
        },
        {
            name: "books",
            label: "Брой книги",
            options: {
                filter: false,
                sort: false
            },
        },
        {
            name: "isApproved",
            label: "Одобрен",
            options: {
                filter: true,
                sort: false,
                filterType: "dropdown",
                filterOptions: {
                    names: ["Да", "Не"],
                },
                customBodyRender: (val: boolean) => {
                    return val ? "Да" : "Не";
                }
            }
        },
        {
            name: "Approve",
            label: "Одобри",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                    if (!authors[dataIndex].isApproved) {
                        return <IconButton
                            size={"small"}
                            onClick={() => {
                                handleApprove(authors[dataIndex].id!!)
                            }}
                        >
                            <DoneIcon/>
                        </IconButton>
                    } else {
                        return <div/>
                    }
                }
            }
        },
        {
            name: "Редактирай",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    return <IconButton
                        size={"small"}
                        component={(props) => (<Link {...props} to={`/admin/author/${authors[rowIndex].id}`}/>)}
                    >
                        <EditIcon/>
                    </IconButton>

                }
            }
        }
    ]

    return (
        <div>
            <MUIDataTable
                title={
                    <Typography variant="h6">
                        Автори
                        {loading && <LinearProgress color="secondary"/>}
                    </Typography>
                }
                data={authors}
                columns={columns}
                options={options}
            />
            <Fab
                color="secondary"
                className={classes.fabSecondary}
                component={(props) => (
                    <Link {...props} to={`/admin/author/new`}/>
                )}
            >
                <AddIcon/>
            </Fab>
        </div>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        authors: store.authorsState.authors,
        count: store.authorsState.count,
        loading: store.authorsState.loading
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IAuthorActions>) => {
    return {
        getAuthors: (filter: AuthorFilter) => dispatch(getAuthors(filter)),
        approveAuthor: (id: number) => dispatch(approveAuthor(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorsTable);
