import React, {FC, useEffect, useState} from "react";
import {Link, RouteProps} from "react-router-dom";
import {Fab, IconButton, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {BookFilter, IAppState, IAuthor, IBook, ResourceUrl, SearchDirection} from "../../types/types";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {approveBook, getBooks, IApprovedBook, IBooksActions, IGotBooksAction} from "../../actions/book/actions";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import LinearProgress from "@material-ui/core/LinearProgress";
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from "mui-datatables";
import DoneIcon from "@material-ui/icons/Done";
import useDebounce from "../../utils/functions";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) => ({
    fabSecondary: {
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'sticky'
    },
    mainList: {
        maxHeight: '70vh',
        overflow: 'auto',
    },
    items: {
        paddingLeft: theme.spacing(2)
    },
    nested: {
        paddingLeft: theme.spacing(10),
    },
}));

interface IProps extends RouteProps {
    getBooks: (bookFilter: BookFilter) => Promise<IGotBooksAction>;
    approveBook: (id: number) => Promise<IApprovedBook>
    loading: boolean;
}

const BooksTable: FC<IProps> = ({
                                    getBooks,
                                    approveBook,
                                    loading
                                }) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [books, setBooks] = useState<IBook[]>([])
    const [count, setCount] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [page, setPage] = useState<number>(0)
    const [, setDirection] = useState<SearchDirection>(SearchDirection.NEXT)
    const [term, setSearchTerm] = useState<string | null>(null)
    const debouncedSearchTerm = useDebounce(term, 800)
    const [approvedFilter, setApprovedFilter] = useState<boolean | null>(null)
    const [defaultImage] = useState<string>(process.env.PUBLIC_URL + '/default-book-cover.svg')

    useEffect(() => {
        if(debouncedSearchTerm) {
            search(0, rowsPerPage, debouncedSearchTerm, null, [])
        }
    }, [debouncedSearchTerm]);


    const search = (newPage: number, rowsNumber: number, searchTerm: string | null, approved: boolean | null, books: Array<IBook>) => {
        let filter = calculateFilter(newPage, rowsNumber, searchTerm, approved, books)
        setDirection(filter.direction);
        setPage(newPage);
        setRowsPerPage(rowsNumber);
        getBooks(filter).then(r => {
            setBooks(r.payload.books)
            setCount(r.payload.count)
        }).catch(e => {
            console.log(e)
            if (e.response) {
                enqueueSnackbar(e.response.data, {
                    variant: 'error'
                })
            }
        })
    }

    const calculateFilter = (newPage: number, rowsNumber: number, searchTerm: string | null, approved: boolean | null, books: Array<IBook>): BookFilter => {
        if (rowsPerPage !== rowsNumber || approvedFilter !== approved || term !== searchTerm) {
            return {
                lastSeenId: null,
                approved: approved,
                term: searchTerm,
                size: rowsNumber,
                direction: SearchDirection.NEXT
            }
        }

        let direction: SearchDirection
        let lastSeenId: number | null = null
        if (rowsNumber === rowsPerPage) {
            if (page <= newPage) {
                direction = SearchDirection.NEXT
                lastSeenId = getLastSeenBookId(SearchDirection.NEXT, books)
            } else {
                direction = SearchDirection.PREVIOUS
                lastSeenId = getLastSeenBookId(SearchDirection.PREVIOUS, books)
            }
        } else {
            direction = SearchDirection.NEXT
        }

        return {
            lastSeenId: lastSeenId,
            approved: approved,
            term: searchTerm,
            size: rowsNumber,
            direction: direction
        }
    }

    const getLastSeenBookId = (direction: SearchDirection, books: Array<IBook>): number | null => {
        if (books.length === 0) {
            return null
        }
        if (direction === SearchDirection.NEXT) {
            return books[books.length - 1].id
        } else {
            return books[0].id
        }
    }

    const handleRowsPerPageUpdate = (rowsNumber: number) => {
        setRowsPerPage(rowsNumber);
        search(0, rowsNumber, term, approvedFilter, books);
    }

    const handleSearchTerm = (searchText: string) => {
        setSearchTerm(searchText)
    }

    const applyFilters = (changedColumn: string, filterList: any[]) => {
        if (filterList[5][0]) {
            if (filterList[5][0] === "Да") {
                setApprovedFilter(true)
                search(page, rowsPerPage, term, true, books)
            } else if (filterList[5][0] === "Не") {
                setApprovedFilter(false)
                search(page, rowsPerPage, term, false, books)
            } else {
                setApprovedFilter(null)
                search(page, rowsPerPage, term, null, books)
            }
        } else {
            setApprovedFilter(null)
            search(page, rowsPerPage, term, null, books)
        }
    }

    const handleApprove = (book: IBook) => {
        if (book.coverImage === null) {
            alert("Book does not have cover image. Please set cover image first")
        } else {
            approveBook(book.id)
                .then(() => {
                    setBooks([])
                    search(page, rowsPerPage, term, approvedFilter, [])
                })
                .catch(e => console.log(e))
        }
    }

    const options: MUIDataTableOptions = {
        filter: true,
        fixedSelectColumn: false,
        selectableRows: 'none',
        viewColumns: false,
        responsive: "vertical",
        serverSide: true,
        count: count,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 50, 100, 500],
        download: false,
        print: false,
        onChangePage: currentPage => search(currentPage, rowsPerPage, term, approvedFilter, books),
        onChangeRowsPerPage: rowsNumber => handleRowsPerPageUpdate(rowsNumber),
        onTableInit: () => search(0, rowsPerPage, term, approvedFilter, books),
        onSearchChange: searchText => handleSearchTerm(searchText),
        onFilterChange: (changedColumn: string, filterList: any[]) => applyFilters(changedColumn, filterList)
    };

    const columns: MUIDataTableColumnDef[] = [
        {
            name: "coverImage",
            label: "Корица",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (image: ResourceUrl | null | undefined) => {
                    if (image) {
                        return <div>
                            <img src={`${image.url}`} alt={"cover"} style={{width: "150px", height: "200px"}}/>
                        </div>
                    } else {
                        return <div>
                            <img src={`${defaultImage}`} alt={"default-cover"} style={{width: "150px", height: "200px"}}/>
                        </div>
                    }
                }
            }
        },
        {
            name: "isbn",
            label: "ISBN",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "title",
            label: "Заглавие",
            options: {
                filter: false,
                sort: false
            },
        },
        {
            name: "authors",
            label: "Автор(и)",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (authors: IAuthor[]) => {
                    return authors.map(author => author.name).join(", ");
                }
            },
        },
        {
            name: "listingCount",
            label: "Брой обяви",
            options: {
                filter: false,
                sort: false
            },
        },
        {
            name: "isApproved",
            label: "Одобрена",
            options: {
                filter: true,
                sort: false,
                filterType: "dropdown",
                filterOptions: {
                    names: ["Да", "Не"],
                },
                customBodyRender: (val: boolean) => {
                    return val ? "Да" : "Не";
                }
            }
        },
        {
            name: "Approve",
            label: "Одобри",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                    if (!books[dataIndex].isApproved) {
                        return <IconButton
                            size={"small"}
                            onClick={() => {
                                handleApprove(books[dataIndex])
                            }}
                        >
                            <DoneIcon/>
                        </IconButton>

                    } else {
                        return <div/>
                    }
                }
            }
        },
        {
            name: "createdAt",
            label: "Създадена на",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (createdAt: number) => {
                    return new Date(createdAt).toLocaleString();
                }
            }
        },
        {
            name: "updatedAt",
            label: "Последно променана на",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (updatedAt: number) => {
                    return new Date(updatedAt).toLocaleString();
                }
            }
        },
        {
            name: "Редактирай",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    return <IconButton
                        size={"small"}
                        component={(props) => (<Link {...props} to={`/admin/book/${books[rowIndex].id}`}/>)}
                    >
                        <EditIcon/>
                    </IconButton>

                }
            }
        }
    ]


    return (
        <div>
            <MUIDataTable
                title={
                    <Typography variant="h6">
                        Книги
                        {loading && <LinearProgress color="secondary"/>}
                    </Typography>
                }
                data={books}
                columns={columns}
                options={options}
            />
            <Fab
                color="secondary"
                className={classes.fabSecondary}
                component={(props) => (
                    <Link {...props} to={`/admin/book/new`}/>
                )}
            >
                <AddIcon/>
            </Fab>
        </div>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        books: store.booksState.books,
        loading: store.booksState.loading
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IBooksActions>) => {
    return {
        getBooks: (bookFilter: BookFilter) => dispatch(getBooks(bookFilter)),
        approveBook: (id: number) => dispatch(approveBook(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BooksTable);
