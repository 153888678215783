import React, {FC, useState} from "react";
import {Link, RouteProps} from "react-router-dom";
import {Avatar, Box, Chip, IconButton, Theme, Typography} from "@material-ui/core";
import {
    IAppState,
    IAuthor,
    IBookListingFlatten,
    ListingFilter,
    ListingStatus,
    SearchDirection
} from "../../types/types";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {IBooksActions} from "../../actions/book/actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import {getListings, IGotListingsAction} from "../../actions/listing/actions";
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from "mui-datatables";
import {makeStyles} from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import { useSnackbar } from 'notistack';

interface IProps extends RouteProps {
    getListings: (listingFilter: ListingFilter) => Promise<IGotListingsAction>;
    loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    fabMain: {
        position: 'absolute',
        bottom: 80,
        right: 18,
    },
    fabSecondary: {
        position: 'absolute',
        bottom: 18,
        right: 18,
    },
    mainList: {
        maxHeight: '70vh',
        overflow: 'auto',
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    items: {
        paddingLeft: theme.spacing(2)
    },
    nested: {
        paddingLeft: theme.spacing(10),
    },
    chip: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

const ListingsTable: FC<IProps> = ({
                                       getListings,
                                       loading
                                   }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const defaultAvatar = process.env.PUBLIC_URL + '/default-account-avatar.svg'
    const [listings, setListings] = useState<IBookListingFlatten[]>([])
    const [count, setCount] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(10)
    const [page, setPage] = useState<number>(0)
    const [, setDirection] = useState<SearchDirection>(SearchDirection.NEXT)
    const [statusFilter, setStatusFilter] = useState<ListingStatus | null>(null)

    const getAvatarUrlOrDefault = (listingView: IBookListingFlatten) => {
        if (listingView.userAvatarUrl) {
            return listingView.userAvatarUrl
        } else {
            return defaultAvatar
        }
    }
    const search = (newPage: number, rowsNumber: number, status: ListingStatus | null, listings: Array<IBookListingFlatten>) => {
        let filter = calculateFilter(newPage, rowsNumber, status, listings)
        setDirection(filter.direction);
        setPage(newPage);
        setRowsPerPage(rowsNumber);
        getListings(filter).then(r => {
            setListings(r.payload.listingViews)
            setCount(r.payload.count)
        }).catch(e => {
            console.log(e)
            if (e.response) {
                enqueueSnackbar(e.response.data, {
                    variant: 'error'
                })
            }
        })
    }

    const calculateFilter = (newPage: number, rowsNumber: number, status: ListingStatus | null, listings: Array<IBookListingFlatten>): ListingFilter => {
        if (rowsPerPage !== rowsNumber || statusFilter !== status) {
            return {
                lastSeenId: null,
                status: status,
                size: rowsNumber,
                direction: SearchDirection.NEXT
            }
        }

        let direction: SearchDirection
        let lastSeenId: number | null = null
        if (rowsNumber === rowsPerPage) {
            if (page <= newPage) {
                direction = SearchDirection.NEXT
                lastSeenId = getLastSeenListingId(SearchDirection.NEXT, listings)
            } else {
                direction = SearchDirection.PREVIOUS
                lastSeenId = getLastSeenListingId(SearchDirection.PREVIOUS, listings)
            }
        } else {
            direction = SearchDirection.NEXT
        }

        return {
            lastSeenId: lastSeenId,
            status: status,
            size: rowsNumber,
            direction: direction
        }
    }

    const getLastSeenListingId = (direction: SearchDirection, listings: Array<IBookListingFlatten>): number | null => {
        if (listings.length === 0) {
            return null
        }
        if (direction === SearchDirection.NEXT) {
            return listings[listings.length - 1].id
        } else {
            return listings[0].id
        }
    }

    const handleRowsPerPageUpdate = (rowsNumber: number) => {
        setRowsPerPage(rowsNumber);
        setListings([])
        search(0, rowsNumber, null, []);
    }

    const applyStatusFilter = (changedColumn: string, filterList: any[]) => {
        if (filterList[3][0]) {
            if (filterList[3][0] === "Изчакваща одобрение") {
                setStatusFilter(ListingStatus.PENDING)
                search(page, rowsPerPage, ListingStatus.PENDING, listings)
            } else if (filterList[3][0] === "Активна") {
                setStatusFilter(ListingStatus.ACTIVE)
                search(page, rowsPerPage, ListingStatus.ACTIVE, listings)
            }  else if (filterList[3][0] === "Отказана") {
                setStatusFilter(ListingStatus.REJECTED)
                search(page, rowsPerPage, ListingStatus.REJECTED, listings)
            } else {
                setStatusFilter(null)
                search(page, rowsPerPage, null, listings)
            }
        } else {
            setStatusFilter(null)
            search(page, rowsPerPage, null, listings)
        }
    }

    const options: MUIDataTableOptions = {
        filter: true,
        fixedSelectColumn: false,
        selectableRows: 'none',
        viewColumns: false,
        responsive: "vertical",
        serverSide: true,
        count: count,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 50, 100],
        download: false,
        print: false,
        search: false,
        onChangePage: currentPage => search(currentPage, rowsPerPage, statusFilter, listings),
        onChangeRowsPerPage: rowsNumber => handleRowsPerPageUpdate(rowsNumber),
        onTableInit: () => search(0, rowsPerPage, statusFilter, listings),
        onFilterChange: (changedColumn: string, filterList: any[]) => applyStatusFilter(changedColumn, filterList)
    };

    const columns: MUIDataTableColumnDef[] = [
        {
            name: "userId",
            label: "Потребител",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const listing = listings[dataIndex]
                    if (listing.userNickname) {
                        return <Chip
                                avatar={<Avatar
                                    className={classes.small}
                                    alt="Remy Sharp"
                                    src={getAvatarUrlOrDefault(listing)}
                                />}
                                label={listing.userNickname}
                                variant="outlined"
                            />
                    } else {
                        return <Chip
                            avatar={<Avatar
                                className={classes.small}
                                alt="Remy Sharp"
                                src={getAvatarUrlOrDefault(listing)}
                            />}
                            label={"Неочаквана грешка..."}
                            variant="outlined"
                        />
                    }
                }
            },
        },
        {
            name: "bookTitle",
            label: "Заглавие",
            options: {
                filter: false,
                sort: false
            },
        },
        {
            name: "bookAuthors",
            label: "Автор(и)",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (authors: IAuthor[]) => {
                    return authors.map(author => author.name).join(", ")
                }
            },
        },
        {
            name: "status",
            label: "Статус",
            options: {
                filter: true,
                sort: false,
                filterOptions: {
                    names: ["Изчакваща одобрение", "Активна", "Отказана"]
                },
                customBodyRender: (status: ListingStatus) => {
                    let stringValue = status.valueOf()
                    if (stringValue === "PENDING") {
                        return <Box color="warning.main">Изчакваща одобрение</Box>
                    } else if (stringValue === "ACTIVE") {
                        return <Box color="success.main">Активна</Box>
                    } else {
                        return <Box color="error.main">Отказана</Box>
                    }
                }
            }
        },
        {
            name: "createdAt",
            label: "Създадена на",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (createdAt: number) => {
                    return new Date(createdAt).toLocaleString();
                }
            }
        },
        {
            name: "Редактирай",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    return <IconButton
                        size={"small"}
                        component={(props) => (<Link {...props} to={`/admin/listing/${listings[rowIndex].id}`}/>)}
                    >
                        <EditIcon/>
                    </IconButton>
                }
            }
        }
    ]

    return (
        <div>
            <MUIDataTable
                title={
                    <Typography variant="h6">
                        Обяви
                        {loading && <LinearProgress color="secondary"/>}
                    </Typography>
                }
                data={listings}
                columns={columns}
                options={options}
            />
        </div>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        listingViews: store.listingsState.listingViews,
        count: store.listingsState.count,
        loading: store.listingsState.loading
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IBooksActions>) => {
    return {
        getListings: (listingFilter: ListingFilter) => dispatch(getListings(listingFilter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingsTable);
