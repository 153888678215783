export type IBookState = {
    readonly books: IBook[];
    readonly count: number;
    readonly loading: boolean;
}

export type IListingState = {
    readonly listingViews: IBookListingFlatten[];
    readonly count: number;
    readonly loading: boolean;
}

export type IReportState = {
    readonly reports: IReportDetails[];
    readonly count: number;
    readonly loading: boolean;
}

export type IUsersState = {
    readonly users: IUserProfile[];
    readonly count: number;
    readonly loading: boolean;
}

export type IFeedbackState = {
    readonly feedbacks: IFeedbackDetails[];
    readonly count: number;
    readonly loading: boolean;
}

export type IAuthorState = {
    readonly authors: IAuthor[];
    readonly foundAuthors: IAuthor[];
    readonly count: number;
    readonly loading: boolean;
}

export type IGenresState = {
    readonly loading: boolean;
}

export type IAuthState = {
    readonly authenticated: boolean,
    readonly user: User | null,
    readonly loading: boolean
}

export interface IAppState {
    booksState: IBookState;
    authState: IAuthState;
    genresState: IGenresState;
    authorsState: IAuthorState;
    listingsState: IListingState;
    reportsState: IReportState;
    feedbacksState: IFeedbackState;
    usersState: IUsersState;
}

export interface IBook {
    id: number,
    isbn: string,
    title: string,
    listingCount: number,
    minListingPrice: number,
    isApproved: boolean,
    authors: IAuthor[],
    genres: IGenre[],
    description: string,
    language: Language,
    cover: BookCover,
    price: number | null,
    year: number | null,
    pages: number | null,
    publisher: string | null,
    coverImage: ResourceUrl | null,
    createdAt: number,
    updatedAt: number
}

export interface BooksPage {
    books: IBook[]
    count: number
}

export interface IAuthor {
    id: number | null,
    name: string,
    isApproved: boolean,
    books: number | null,
    createdAt: number,
    updatedAt: number
}

export interface AuthorsPage {
    authors: Array<IAuthor>
    count: number
}

export interface AuthorFilter {
    lastSeenId: number | null,
    direction: SearchDirection,
    size: number,
    term: string | null,
    approved: boolean | null
}

export interface IBookForm {
    id: number | null,
    isbn: string,
    title: string,
    authors: IAuthor[],
    description: string,
    genres: IGenre[],
    language: string | null,
    cover: string | null,
    price: number | null,
    year: number | null,
    pages: number | null,
    publisher: string | null,
    coverImageResource: ImageResource
}

export interface ImageResource {
    filename: string,
    contentType: string,
    base64Content: string | ArrayBuffer | null
}

export interface ResourceUrl {
    id: number,
    url: string
}

export interface IGenre {
    id: number,
    parentId: number | null,
    books: number | null,
    title: string
}

export interface User {
    id: number;
    role: string;
    accessToken: string,
    refreshToken: string
}

export interface Credentials {
    email: string,
    password: string
}

export enum BookCover {
    HARD = "Твърда",
    SOFT = "Мека"
}

export enum Language {
    BG = "Български",
    EN = "Английски",
    DE = "Немски",
    FR = "Френски",
    ES = "Испански",
    IT = "Италиански",
    RU = "Руски",
    TR = "Турски",
    OTHER = "Други"
}

export enum BookQuality {
    USED = "Използвана",
    GOOD = "Добро",
    EXCELLENT = "Отлично"
}

export interface IBookListing {
    id: number,
    bookId: number,
    userId: number,
    userNickname: string | null,
    userAvatarUrl: string | null,
    quality: BookQuality,
    description: string,
    frontCover: ResourceUrl,
    backCover: ResourceUrl,
    attachedResources: ResourceUrl[],
    location: string,
    price: number,
    status: ListingStatus,
    deliveryOnBuyer: boolean,
    deliveryOnSeller: boolean,
    deliveryInPerson: boolean,
    rejectReason: RejectReason | null,
    rejectMessage: string | null,
    createdAt: number,
    updatedAt: number
}

export interface IBookListingView {
    listing: IBookListing,
    book: IBook
}

export interface IBookListingFlatten {
    id: number,
    userId: number,
    bookId: number,
    bookTitle: string,
    bookAuthors: Array<IAuthor>,
    userNickname: string | null,
    userAvatarUrl: string | null,
    status: ListingStatus,
    createdAt: number,
    updatedAt: number
}

export interface ListingsPage {
    listingViews: IBookListingFlatten[],
    count: number
}

export interface ReportsPage {
    reports: IReportDetails[],
    count: number
}

export interface UsersPage {
    users: IUserProfile[],
    count: number
}

export interface FeedbacksPage {
    feedbacks: IFeedbackDetails[],
    count: number
}

export interface IReportDetails {
    id: number,
    reporter: IReporter,
    message: string,
    reportedProfile: IUserProfile | null,
    reportedListing: IListingMetadata | null,
    createdAt: number,
}

export interface IFeedbackDetails {
    id: number,
    reporter: IReporter | null,
    message: string,
    createdAt: number,
}

export interface IUserProfile {
    id: number,
    email: string,
    nickname: string,
    firstName: string | null,
    lastName: string | null,
    bio: string | null,
    location: string | null,
    avatarUrl: string | null,
    emailVerified: boolean,
    subscribed: boolean,
    disabled: boolean,
    listingsCount: number | null
}

export interface IListingMetadata {
    listingId: number,
    bookId: number,
    bookTitle: string,
    frontCover: string
}

export interface IReporter {
    id: number,
    nickname: string,
    avatarUrl: string | null
}


export interface IApproveListingForm {
    listingId: number,
    location: string,
    removedAttachmentIds: number[],
    book: IBookForm | null
}

export enum SearchDirection {
    NEXT = "next",
    PREVIOUS = "previous"
}


export interface BookFilter {
    lastSeenId: number | null,
    direction: SearchDirection,
    size: number,
    term: string | null,
    approved: boolean | null
}

export enum ListingStatus {
    PENDING = "Pending",
    ACTIVE = "Active",
    REJECTED = "Rejected"
}

export enum RejectReason {
    WRONG_TITLE_LANGUAGE= 'Заглавието не е на подходящия език',
    WRONG_DESCRIPTION_LANGUAGE = 'Описанието не е на кирилица',
    LISTING_USED_FOR_AD = 'Заглавието или описанието съдържат нерелевантна информация',
    MULTIPLE_LISTINGS = 'Обявата се опитва да продаде повече от 1 заглавие',
    LISTING_IS_NOT_FOR_SALE = 'Обявата е от тип "търся/изкупувам"',
    WRONG_IMAGES = 'Снимките не отговарят на критериите',
    WRONG_ISBN = 'Информацията за обявата не отговаря на ISBN номера',
    MISLEADING_DETAILS = 'Грешка в детайлите на обявата',
    ISBN_DOES_NOT_EXIST = 'Несъществуващ ISBN номер',
    BOOK_STATE_IS_WRONG = 'Състоянието не е правилно дефинирано',
    WRONG_LOCATION = 'Локацията е грешна'
}

export interface ListingFilter {
    lastSeenId: number | null,
    direction: SearchDirection,
    size: number,
    status: ListingStatus | null
}

export interface ReportFilter {
    lastSeenId: number | null,
    direction: SearchDirection,
    size: number
}

export interface UserFilter {
    lastSeenId: number | null,
    direction: SearchDirection,
    term: string | null,
    size: number
}

export interface FeedbackFilter {
    lastSeenId: number | null,
    direction: SearchDirection,
    size: number
}
