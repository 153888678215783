import React, {FC, useEffect, useState} from "react";
import {match, RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import {Button, Checkbox, FormControlLabel, Grid, TextField} from "@material-ui/core";
import {IAppState} from "../../types/types";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {IAuthorActions} from "../../actions/author/actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import {getUser, IGotUserAction, IUpdatedDisableFlag, updateDisableFlag} from "../../actions/user/actions";


interface UserEditorParams {
    id: string;
}

interface IProps extends RouteComponentProps<UserEditorParams> {
    getUser: (id: number) => Promise<IGotUserAction>;
    disableUser: (id: number, disable: boolean) => Promise<IUpdatedDisableFlag>
    match: match<UserEditorParams>;
    loading: boolean;
}

const UserEditor: FC<IProps> = ({
                                    getUser,
                                    disableUser,
                                    match,
                                    loading
                                }) => {

    const history = useHistory();
    const defaultAvatar = process.env.PUBLIC_URL + '/default-account-avatar.svg';
    const [id, setId] = useState<number>(-1)
    const [email, setEmail] = useState<string>("")
    const [nickname, setNickname] = useState<string>("")
    const [avatarUrl, setAvatarUrl] = useState<string>(defaultAvatar)
    const [firstName, setFirstName] = useState<string>("")
    const [lastName, setLastName] = useState<string>("")
    const [bio, setBio] = useState<string>("")
    const [location, setLocation] = useState<string>("")
    const [subscribed, setSubscribed] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [listingsCount, setListingsCount] = useState<number>(0)

    useEffect(() => {
        if (match.params.id !== "new") {
            getUser(+match.params.id).then((value => {
                setId(value.payload.user.id)
                setEmail(value.payload.user.email)
                setNickname(value.payload.user.nickname)
                if (value.payload.user.avatarUrl) {
                    setAvatarUrl(value.payload.user.avatarUrl)
                }
                if (value.payload.user.firstName) {
                    setFirstName(value.payload.user.firstName)
                }
                if (value.payload.user.lastName) {
                    setLastName(value.payload.user.lastName)
                }
                if (value.payload.user.bio) {
                    setBio(value.payload.user.bio)
                }
                if (value.payload.user.location) {
                    setLocation(value.payload.user.location)
                }
                if (value.payload.user.listingsCount) {
                    setListingsCount(value.payload.user.listingsCount)
                }
                setSubscribed(value.payload.user.subscribed)
                setDisabled(value.payload.user.disabled)
            }))
        }
    }, [getUser, match.params.id, match.path]);

    const handleSubmit = (disable: boolean) => {
        disableUser(id, disable).then(() => {
                history.goBack()
            }
        ).catch(reason => console.log(reason))

    };

    return (
        <Grid container spacing={4}>
            {(loading) && <LinearProgress color="secondary"/>}
            <Grid item xs={12}>
                <TextField
                    id="email"
                    label="Имейл"
                    fullWidth
                    value={email}
                    disabled={true}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="nickname"
                    label="Никнейм"
                    fullWidth
                    value={nickname}
                    disabled={true}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <div>
                    <img src={`${avatarUrl}`} alt={"imageProfile"} style={{width: "150px", height: "200px"}}/>
                </div>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="firstname"
                    label="Първо име"
                    fullWidth
                    value={firstName}
                    disabled={true}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="lastname"
                    label="Фамилно име"
                    fullWidth
                    value={lastName}
                    disabled={true}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="bio"
                    label="Биография"
                    fullWidth
                    value={bio}
                    disabled={true}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="location"
                    label="Местоположение"
                    fullWidth
                    value={location}
                    disabled={true}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="listingCount"
                    label="Брой обяви"
                    fullWidth
                    value={"" + listingsCount}
                    disabled={true}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={subscribed} name="subscribed"/>}
                    label="Абониран за нотификации"
                />
            </Grid>
            <Grid item xs={12} container direction="row" justify="flex-end" alignItems={"flex-end"}>
                {disabled &&
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit(false)}
                >
                    Активирай
                </Button>
                }
                {!disabled &&
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit(true)}
                >
                    Деактивирай
                </Button>
                }
                &nbsp; &nbsp; &nbsp;
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.goBack()}
                >
                    Назад
                </Button>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        loading: store.usersState.loading,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IAuthorActions>) => {
    return {
        getUser: (id: number) => dispatch(getUser(id)),
        disableUser: (id: number, disable: boolean) => dispatch(updateDisableFlag(id, disable))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserEditor));
