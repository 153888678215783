import {IReportDetails, ReportFilter, ReportsPage} from '../../types/types'
import {ThunkAction} from "redux-thunk";
import api from '../api'
import {ActionCreator, Dispatch} from "redux";

export const GETTING_REPORTS = "GETTING_REPORTS";
export const GOT_REPORTS = "GOT_REPORTS";

export interface IGettingReportsAction {
    type: typeof GETTING_REPORTS
}

export interface IGotReportsAction {
    type: typeof GOT_REPORTS
    payload: {
        reports: IReportDetails[]
        count: number
    }
}


export const getReports: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IGotReportsAction>,
    // The type for the data within the last action
    ReportsPage[],
    // The type of the parameter for the nested function
    ReportFilter,
    // The type of the last action to be dispatched
    IGotReportsAction>> = (reportFilter: ReportFilter) => {
    return async (dispatch: Dispatch) => {
        const gettingListingsAction: IGettingReportsAction = {
            type: GETTING_REPORTS
        };
        let params = `size=${reportFilter.size}&direction=${reportFilter.direction}`
        if (reportFilter.lastSeenId !== null) {
            params = params + `&lastSeenId=${reportFilter.lastSeenId}`
        }
        dispatch(gettingListingsAction);
        const axiosResponse = await api.get<ReportsPage>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/report/list?${params}`)
        const gotListingsAction: IGotReportsAction = {
            payload: {
                reports: axiosResponse.data.reports,
                count: axiosResponse.data.count
            },
            type: GOT_REPORTS
        };
        return dispatch(gotListingsAction);
    };
};

export type IReportsActions =
    | IGettingReportsAction
    | IGotReportsAction;
