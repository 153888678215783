import axios from 'axios';
import {User} from "../types/types";
import {stringify} from "querystring";


const api = function() {
    const defaultOptions = {
        baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const token = getAccessToken();
        if (token !== null) {
            config.headers.Authorization = token ? `Bearer ${token}` : '';
        }
        return config;
    });

    instance.interceptors.response.use((response) => {
            return response
        },
        function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401 && originalRequest.url === `${process.env.REACT_APP_API_BASE_URL}/api/user/token/access/get`) {
                clearTokens();
                window.location.replace(`${process.env.REACT_APP_API_BASE_URL}`)
                return Promise.reject(error);
            }
            if (error.response.status === 401 && !originalRequest._retry) {
                const body = {
                    refreshToken: getRefreshToken(),
                    deviceId: getDeviceId()
                }
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
                originalRequest._retry = true;
                return instance.post<User>(`${process.env.REACT_APP_API_BASE_URL}/api/user/token/access/get`, stringify(body), config)
                    .then(res => {
                        if (res.status === 201) {
                            // 1) put token to LocalStorage
                            storeTokens(res.data.accessToken, res.data.refreshToken, res.data)
                            // 2) Change Authorization header
                            instance.defaults.headers.common['Authorization'] = 'Bearer ' + getAccessToken();

                            return instance(originalRequest);
                        }
                    })
            }

            // return Error object with Promise
            return Promise.reject(error);
        });

    return instance;
};

export default api();

export function storeDeviceId(deviceId: string) {
    localStorage.setItem('deviceId', deviceId)
}

export function getDeviceId(): string | null {
    return localStorage.getItem('deviceId')
}

export function storeTokens(accessToken: string, refreshToken: string, user: User) {
    localStorage.setItem('access_token', accessToken)
    localStorage.setItem('refresh_token', refreshToken)
    localStorage.setItem('user', JSON.stringify(user, null, 2))
}

export function getAccessToken(): string | null {
    return localStorage.getItem('access_token');
}

export function getRefreshToken(): string | null {
    return localStorage.getItem("refresh_token");
}

export function clearTokens() {
    localStorage.clear()
}
