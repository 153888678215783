import React, {ChangeEvent, FC, Fragment, useEffect, useState} from "react";
import {match, RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import {
    AppBar,
    Button,
    Checkbox,
    CircularProgress,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Fade,
    FormControlLabel,
    Grid,
    Input, InputLabel,
    Link,
    MenuItem,
    Select,
    Slide,
    TextField,
    Theme,
    Toolbar,
    Tooltip,
    Typography
} from "@material-ui/core";
import {
    BookCover,
    BookQuality,
    IApproveListingForm,
    IAppState,
    IAuthor,
    IBookForm,
    IGenre, Language,
    ListingStatus,
    RejectReason,
    ResourceUrl
} from "../../types/types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {getGenresAction, IGotGenresAction} from "../../actions/genre/actions";
import {IFoundAuthors, searchAuthors} from "../../actions/author/actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
    approveListing,
    deleteListing,
    getListing,
    IApprovedListingAction, IChangedListingBook,
    IDeletedListingAction,
    IGotListingAction,
    IListingsActions,
    IRejectedListingAction,
    rejectListing, updateListingBook
} from "../../actions/listing/actions";
import Avatar from "@material-ui/core/Avatar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import Delete from '@material-ui/icons/Delete';
import DeleteIcon from '@material-ui/icons/Delete';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import {Info} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import {TransitionProps} from "@material-ui/core/transitions";
import { useSnackbar } from 'notistack';


interface ListingEditorParams {
    id: string;
}

interface IProps extends RouteComponentProps<ListingEditorParams> {
    getGenres: () => Promise<IGotGenresAction>;
    getListing: (id: number) => Promise<IGotListingAction>;
    approveListing: (approveForm: IApproveListingForm) => Promise<IApprovedListingAction>;
    searchAuthors: (name: string) => Promise<IFoundAuthors>;
    rejectListing: (listingId: number, reason: string, message: string) => Promise<IRejectedListingAction>
    deleteListing: (listingId: number) => Promise<IDeletedListingAction>,
    updateIsbn: (listingId: number, isbn: string) => Promise<IChangedListingBook>
    match: match<ListingEditorParams>;
    loading: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        appBar: {
            position: "relative"
        },
        gridList: {
            flexWrap: 'nowrap',
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)'
        },
        title: {
            color: theme.palette.primary.light,
        },
        titleBar: {
            background:
                'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        },
    }),
);

function SlideTransition(props: TransitionProps) {
    return <Slide {...props} direction="up"/>;
}

const ListingEditor: FC<IProps> = ({
                                       getGenres,
                                       getListing,
                                       approveListing,
                                       searchAuthors,
                                       rejectListing,
                                       deleteListing,
                                       updateIsbn,
                                       match,
                                       loading
                                   }) => {

    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const rejectReasons = Object.keys(RejectReason).map(value => value)
    const languages = Object.keys(Language).map(value => value)
    const covers = Object.keys(BookCover).map(value => value)
    const [listingBookApproved, setListingBookApproved] = useState(false)
    const [listingId, setListingId] = useState<number>(0)
    const [listingBookId, setListingBookId] = useState<number>(0)
    const [listingUserNickname, setListingUserNickname] = useState<string>("")
    const [listingUserAvatarUrl, setListingUserAvatarUrl] = useState<string>("")
    const [listingBookQuality, setListingBookQuality] = useState<string>("")
    const [listingDescription, setListingDescription] = useState("")
    const [frontCoverAttachment, setFrontCoverAttachment] = useState<ResourceUrl | null>(null)
    const [backCoverAttachment, setBackCoverAttachment] = useState<ResourceUrl | null>(null)
    const [listingAttachments, setListingAttachments] = useState<ResourceUrl[]>([])
    const [listingPrice, setListingPrice] = useState<number>(0.0)
    const [listingRejected, setListingReject] = useState<boolean>(false)
    const [listingCount, setListingCount] = useState(0)
    const [listingStatus, setListingStatus] = useState<ListingStatus | null>(null)
    const [listingLocation, setListingLocation] = useState<string>("")
    const [deliveryOnBuyer, setDeliveryOnBuyer] = useState<boolean>(false)
    const [deliveryOnSeller, setDeliveryOnSeller] = useState<boolean>(false)
    const [deliveryInPerson, setDeliveryInPerson] = useState<boolean>(false)
    const [removedAttachments] = useState<number[]>([])
    const [bookIsbn, setBookIsbn] = useState("")
    const [bookTitle, setBookTitle] = useState("")
    const [bookDescription, setBookDescription] = useState("")
    const [bookLanguage, setBookLanguage] = useState("")
    const [bookPrice, setBookPrice] = useState<number | null>(null)
    const [bookYear, setBookYear] = useState<number | null>(null)
    const [bookPages, setBookPages] = useState<number | null>(null)
    const [bookPublisher, setBookPublisher] = useState<string>("")
    const [bookCover, setBookCover] = useState<string>("")
    const [bookGenres, setBookGenres] = useState<Array<IGenre>>([])
    const [genres, setGenres] = useState<Array<IGenre>>([])
    const [approveButtonDisabled, setApproveButtonDisabled] = useState(false);
    const [rejectButtonDisabled, setRejectButtonDisabled] = useState(false);
    const [image, setImage] = useState<string | ArrayBuffer>(process.env.PUBLIC_URL + '/default-book-cover.svg')
    const [bookCoverFilename, setBookCoverFilename] = useState("default-book-cover.svg")
    const [bookCoverImageContentType, setBookCoverImageContentType] = useState("")
    const [bookCoverBase64Content, setBookCoverBase64Content] = useState<string | ArrayBuffer | null>(null)
    const [savedImageUrl, setSavedImageUrl] = useState<string | null>(null)
    const [selectedTile, setSelectedTile] = useState<ResourceUrl | null>(null)
    const [deleteClicked, setDeleteClicked] = useState<boolean>(false)
    const [rejectClicked, setRejectClicked] = useState<boolean>(false)
    const [rejectReason, setRejectReason] = useState<string>("")
    const [rejectMessage, setRejectMessage] = useState<string>("")
    const [rejectedReason, setRejectedReason] = useState<string>("")
    const [rejectedMessage, setRejectedMessage] = useState<string>("")
    const [bookAuthors, setBookAuthors] = useState<Array<IAuthor>>([]);
    const [authorOptions, setAuthorOptions] = useState<Array<IAuthor>>([]);
    const [changedIsbn, setChangedIsbn] = useState<string>("")
    const [changeIsbnClicked, setChangeIsbnClicked] = useState<boolean>(false)

    const [imagePreviewState, setImagePreviewState] = React.useState<{
        open: boolean;
        Transition: React.ComponentType<TransitionProps & { children?: React.ReactElement<any, any> }>;
    }>({
        open: false,
        Transition: Fade,
    });

    const bookCoverImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        let files = event.target.files
        if (files) {
            let file = files.item(0);
            if (file) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    if (file && reader.result) {
                        setBookCoverFilename(file.name)
                        setBookCoverImageContentType(file.type)
                        setBookCoverBase64Content(reader.result)
                        setImage(reader.result)
                        setSavedImageUrl(null)
                    }
                };
            }
        }
    }

    const handleAuthorSearch = (searchTerm: string) => {
        searchAuthors(searchTerm).then(r => {
            setAuthorOptions(r.payload.authors)
        }).catch(e => {
            console.log(e)
            if (e.response) {
                enqueueSnackbar(e.response.data, {
                    variant: 'error',
                    style: { whiteSpace: 'pre-line' }
                })
            }
        })
    }

    useEffect(() => {
        getGenres().then((genres) => {
            setGenres(genres.payload.categories)
        });

        getListing(+match.params.id)
            .then(r => {
                setListingBookApproved(r.payload.listing.book.isApproved)
                setListingId(r.payload.listing.listing.id)
                setListingBookId(r.payload.listing.listing.bookId)
                setListingBookQuality(getBookQualityDescription(r.payload.listing.listing.quality))
                if (r.payload.listing.listing.userNickname) {
                    setListingUserNickname(r.payload.listing.listing.userNickname)
                }
                if (r.payload.listing.listing.userAvatarUrl) {
                    setListingUserAvatarUrl(r.payload.listing.listing.userAvatarUrl)
                } else {
                    setListingUserAvatarUrl(process.env.PUBLIC_URL + '/default-account-avatar.svg')
                }
                setListingDescription(r.payload.listing.listing.description)
                setFrontCoverAttachment(r.payload.listing.listing.frontCover)
                setBackCoverAttachment(r.payload.listing.listing.backCover)
                setListingAttachments(r.payload.listing.listing.attachedResources)
                setListingPrice(r.payload.listing.listing.price)
                setListingStatus(r.payload.listing.listing.status)
                setListingLocation(r.payload.listing.listing.location)
                setDeliveryOnBuyer(r.payload.listing.listing.deliveryOnBuyer)
                setDeliveryOnSeller(r.payload.listing.listing.deliveryOnSeller)
                setDeliveryInPerson(r.payload.listing.listing.deliveryInPerson)
                if (r.payload.listing.listing.rejectReason !== null && r.payload.listing.listing.rejectMessage !== null) {
                    setListingReject(true)
                    setRejectedReason("" + r.payload.listing.listing.rejectReason)
                    setRejectedMessage(r.payload.listing.listing.rejectMessage)
                } else {
                    setListingReject(false)
                }
                setBookIsbn(r.payload.listing.book.isbn)
                setBookTitle(r.payload.listing.book.title)
                setListingCount(r.payload.listing.book.listingCount)
                if (r.payload.listing.book.language) {
                    setBookLanguage(getLanguageDescription(r.payload.listing.book.language))
                }
                setBookCover(getBookCoverDescription(r.payload.listing.book.cover))
                setBookAuthors(r.payload.listing.book.authors)
                setBookDescription(r.payload.listing.book.description)
                setBookGenres(r.payload.listing.book.genres)
                setBookPages(r.payload.listing.book.pages)
                setBookYear(r.payload.listing.book.year)
                setBookPrice(r.payload.listing.book.price)
                if (r.payload.listing.book.publisher) {
                    setBookPublisher(r.payload.listing.book.publisher)
                }
                if (r.payload.listing.book.coverImage) {
                    setSavedImageUrl(r.payload.listing.book.coverImage.url)
                }

            }).catch(e => {
                console.log(e)
                if (e.response) {
                    enqueueSnackbar(e.response.data, {
                        variant: 'error',
                        style: { whiteSpace: 'pre-line' }
                    })
                }
                history.push("/admin/listing")
            });
    }, [getGenres, getListing, match.params.id]);

    useEffect(() => {
        if (listingStatus !== null) {
            if (listingRejected || listingStatus.valueOf() !== "PENDING") {
                setApproveButtonDisabled(false)
                setRejectButtonDisabled(false)
            } else {
                setApproveButtonDisabled(false)
                setRejectButtonDisabled(false)
            }
        } else {
            setApproveButtonDisabled(false)
            setRejectButtonDisabled(false)
        }
    }, [listingRejected, listingStatus]);

    const handleReject = () => {
        rejectListing(listingId, rejectReason, rejectMessage)
            .then(() => {
                enqueueSnackbar("Обявата беше отказана успешно", {
                    variant: 'success'
                })
                history.push("/admin/listing")
            })
            .catch(e => {
                console.log(e)
                if (e.response) {
                    enqueueSnackbar(e.response.data, {
                        variant: 'error',
                        style: { whiteSpace: 'pre-line' }
                    })
                }
            })
    }

    const handleDelete = () => {
        deleteListing(listingId)
            .then(() => {
                enqueueSnackbar("Обявата беше изтрита успешно", {
                    variant: 'success'
                })
                history.push("/admin/listing")
            })
            .catch(e => {
                console.log(e)
                if (e.response) {
                    enqueueSnackbar(e.response.data, {
                        variant: 'error',
                        style: { whiteSpace: 'pre-line' }
                    })
                }
            })
    }

    const handleUpdateIsbn = () => {
        updateIsbn(listingId, changedIsbn)
            .then(
                r => {
                    setListingBookApproved(r.payload.listing.book.isApproved)
                    setListingId(r.payload.listing.listing.id)
                    setListingBookId(r.payload.listing.listing.bookId)
                    setListingBookQuality(getBookQualityDescription(r.payload.listing.listing.quality))
                    if (r.payload.listing.listing.userNickname) {
                        setListingUserNickname(r.payload.listing.listing.userNickname)
                    }
                    if (r.payload.listing.listing.userAvatarUrl) {
                        setListingUserAvatarUrl(r.payload.listing.listing.userAvatarUrl)
                    } else {
                        setListingUserAvatarUrl(process.env.PUBLIC_URL + '/default-account-avatar.svg')
                    }
                    setListingDescription(r.payload.listing.listing.description)
                    setFrontCoverAttachment(r.payload.listing.listing.frontCover)
                    setBackCoverAttachment(r.payload.listing.listing.backCover)
                    setListingAttachments(r.payload.listing.listing.attachedResources)
                    setListingPrice(r.payload.listing.listing.price)
                    setListingStatus(r.payload.listing.listing.status)
                    setListingLocation(r.payload.listing.listing.location)
                    setDeliveryOnBuyer(r.payload.listing.listing.deliveryOnBuyer)
                    setDeliveryOnSeller(r.payload.listing.listing.deliveryOnSeller)
                    setDeliveryInPerson(r.payload.listing.listing.deliveryInPerson)
                    if (r.payload.listing.listing.rejectReason !== null && r.payload.listing.listing.rejectMessage !== null) {
                        setListingReject(true)
                        setRejectedReason("" + r.payload.listing.listing.rejectReason)
                        setRejectedMessage(r.payload.listing.listing.rejectMessage)
                    } else {
                        setListingReject(false)
                    }
                    setBookIsbn(r.payload.listing.book.isbn)
                    setBookTitle(r.payload.listing.book.title)
                    setListingCount(r.payload.listing.book.listingCount)
                    if (r.payload.listing.book.language) {
                        setBookLanguage(getLanguageDescription(r.payload.listing.book.language))
                    }
                    setBookCover(getBookCoverDescription(r.payload.listing.book.cover))
                    setBookAuthors(r.payload.listing.book.authors)
                    setBookDescription(r.payload.listing.book.description)
                    setBookGenres(r.payload.listing.book.genres)
                    setBookPages(r.payload.listing.book.pages)
                    setBookYear(r.payload.listing.book.year)
                    setBookPrice(r.payload.listing.book.price)
                    if (r.payload.listing.book.publisher) {
                        setBookPublisher(r.payload.listing.book.publisher)
                    }
                    if (r.payload.listing.book.coverImage) {
                        setSavedImageUrl(r.payload.listing.book.coverImage.url)
                    }

                    enqueueSnackbar("ISBN-a беше сменен успешно", {
                        variant: 'success'
                    })
                }
            ).catch(e => {
                console.log(e)
                if (e.response) {
                    enqueueSnackbar(e.response.data, {
                        variant: 'error',
                        style: { whiteSpace: 'pre-line' }
                    })
                }
            })

        closeChangeIsbn()

    }

    const openRejectDialog = () => {
        setRejectClicked(true)
    }

    const closeRejectDialog = () => {
        setRejectClicked(false)
    }

    const openDeleteDialog = () => {
        setDeleteClicked(true)
    }

    const closeDeleteDialog = () => {
        setDeleteClicked(false)
    }

    const openChangeIsbn = () => {
        setChangeIsbnClicked(true)
    }

    const closeChangeIsbn = () => {
        setChangeIsbnClicked(false)
    }

    const handleApproval = () => {
        let book: IBookForm | null = null
        if (!listingBookApproved) {
            book = {
                id: listingBookId,
                description: bookDescription,
                title: bookTitle,
                isbn: bookIsbn,
                authors: bookAuthors,
                genres: bookGenres,
                cover: getBookCoverFromDescription(bookCover),
                language:getLanguageFromDescription(bookLanguage),
                price: bookPrice,
                pages: bookPages,
                year: bookYear,
                publisher: bookPublisher,
                coverImageResource: {
                    base64Content: bookCoverBase64Content,
                    contentType: bookCoverImageContentType,
                    filename: bookCoverFilename
                }
            }
        }
        approveListing({
            listingId: listingId,
            location: listingLocation,
            removedAttachmentIds: removedAttachments,
            book: book
        }).then(() => {
            enqueueSnackbar("Обявата беше одобрена успешно", {
                variant: 'success'
            })
            history.push("/admin/listing")
        }).catch(e => {
            console.log(e)
            if (e.response) {
                enqueueSnackbar(e.response.data, {
                    variant: 'error',
                    style: { whiteSpace: 'pre-line' }
                })
            }
        })
    };



    const deleteAttachment = (resourceId: number) => {
        if (!approveButtonDisabled) {
            let updated = listingAttachments.filter(resource => resource.id !== resourceId)
            setListingAttachments(updated)
            removedAttachments.push(resourceId)
        }
    }

    const handleAttachmentClose = () => {
        setSelectedTile(null);
    };

    const displayAttachment = (resourceUrl: ResourceUrl) => {
        setSelectedTile(resourceUrl)
        handleAttachmentPreview(SlideTransition)
    }

    const handleAttachmentPreview = (
        Transition: React.ComponentType<TransitionProps & { children?: React.ReactElement<any, any> }>,
    ) => () => {
        setImagePreviewState({
            open: true,
            Transition,
        });
    };


    const handleRejectReasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRejectReason(event.target.value as string);
        setRejectMessage(event.target.value as string)
    };

    const getBookCoverFromDescription = (name: string): string | null => {
        let map = new Map<string, string>();
        Object.keys(BookCover).forEach(value => {
                map.set(BookCover[value as keyof typeof BookCover], value)
            }
        )
        let value = map.get(name)
        if (value) {
            return value
        } else {
            return null
        }
    }

    const getBookCoverDescription = (bookCover: BookCover): string => {
        let map = new Map<string, string>();
        Object.keys(BookCover).forEach(value => {
                map.set(value, BookCover[value as keyof typeof BookCover])
            }
        )
        let value = map.get(bookCover)
        if (value) {
            return value
        } else {
            return ""
        }
    }

    const getLanguageFromDescription = (name: string): string | null => {
        let map = new Map<string, string>();
        Object.keys(Language).forEach(value => {
                map.set(Language[value as keyof typeof Language], value)
            }
        )
        let value = map.get(name)
        if (value) {
            return value
        } else {
            return null
        }
    }

    const getBookQualityDescription = (bookQuality: BookQuality): string => {
        let map = new Map<string, string>();
        Object.keys(BookQuality).forEach(value => {
                map.set(value, BookQuality[value as keyof typeof BookQuality])
            }
        )
        let value = map.get(bookQuality)
        if (value) {
            return value
        } else {
            return ""
        }
    }

    const getLanguageDescription = (language: Language): string => {
        let map = new Map<string, string>();
        Object.keys(Language).forEach(value => {
                map.set(value, Language[value as keyof typeof Language])
            }
        )
        let value = map.get(language)
        if (value) {
            return value
        } else {
            return ""
        }
    }

    const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBookLanguage(event.target.value as string);
    };

    const handleBookCoverChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBookCover(event.target.value as string);
    };

    return (
        <Fragment>
            {(loading) && <LinearProgress color="secondary"/>}
            {listingBookApproved &&
            <>
                <Typography variant="h6" gutterBottom>
                    Детайли за вече одобрената книга
                </Typography>
                <Divider/>
                <Grid container spacing={4}>
                    <Grid container item xs={12}>
                        <Link id={"approvedBookLink"} href={`/admin/book/${listingBookId}`} target="_blank"
                              rel="noreferrer">
                            {bookTitle}
                        </Link>
                    </Grid>
                </Grid>
                <br/>
            </>
            }
            <Typography variant="h6" gutterBottom>
                Детайли за обявата
            </Typography>
            <Divider/>
            <Grid container spacing={4}>
                <Grid container item xs={12}>
                    <Tooltip title={`User ${listingUserNickname}`} aria-label="username">
                        <Avatar alt="Remy Sharp" src={listingUserAvatarUrl}/>
                    </Tooltip>
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        fullWidth
                        id="bookQuality"
                        label="Качество на книгата"
                        value={listingBookQuality}
                        disabled={true}
                        variant="outlined"
                    />
                </Grid>
                <Grid container item xs={12} >
                    <TextField
                        fullWidth
                        id="listingDescription"
                        label="Описание на обявата"
                        multiline
                        value={listingDescription}
                        disabled={true}
                        variant="outlined"
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        fullWidth
                        id="listingPrice"
                        label="Цена на обявата"
                        value={listingPrice}
                        disabled={true}
                        variant="outlined"
                    />
                </Grid>
                <Grid container item xs={12}>
                    <div>Прикачени файлове:</div>
                </Grid>
                <Grid container item xs={12}>
                    <div className={classes.root}>
                        <GridList className={classes.gridList} cols={2.5}>
                            {frontCoverAttachment &&
                            <GridListTile key={frontCoverAttachment.id}>
                                <img alt={""} src={frontCoverAttachment.url}/>
                                <GridListTileBar
                                    title={"Предна корица"}
                                    classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                    }}
                                    actionIcon={
                                        <>
                                            <IconButton key={`preview-${frontCoverAttachment.id}`}
                                                        onClick={() => displayAttachment(frontCoverAttachment)}>
                                                <Info className={classes.title}/>
                                            </IconButton>
                                            <IconButton key={`delete-${frontCoverAttachment.id}`}
                                                        onClick={() => deleteAttachment(frontCoverAttachment.id)}>
                                                <Delete className={classes.title}/>
                                            </IconButton>
                                        </>
                                    }
                                />
                            </GridListTile>
                            }
                            {backCoverAttachment &&
                            <GridListTile key={backCoverAttachment.id}>
                                <img alt={""} src={backCoverAttachment.url}/>
                                <GridListTileBar
                                    title={"Задна корица"}
                                    classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                    }}
                                    actionIcon={
                                        <>
                                            <IconButton key={`preview-${backCoverAttachment.id}`}
                                                        onClick={() => displayAttachment(backCoverAttachment)}>
                                                <Info className={classes.title}/>
                                            </IconButton>
                                            <IconButton key={`delete-${backCoverAttachment.id}`}
                                                        onClick={() => deleteAttachment(backCoverAttachment.id)}>
                                                <Delete className={classes.title}/>
                                            </IconButton>
                                        </>
                                    }
                                />
                            </GridListTile>
                            }
                            {listingAttachments.map((attachment) => (
                                <GridListTile key={attachment.id}>
                                    <img alt={""} src={attachment.url}/>
                                    <GridListTileBar
                                        title={"Допълнителни снимки"}
                                        classes={{
                                            root: classes.titleBar,
                                            title: classes.title,
                                        }}
                                        actionIcon={
                                            <>
                                                <IconButton key={`preview-${attachment.id}`}
                                                            onClick={() => displayAttachment(attachment)}>
                                                    <Info className={classes.title}/>
                                                </IconButton>
                                                <IconButton key={`delete-${attachment.id}`}
                                                            onClick={() => deleteAttachment(attachment.id)}>
                                                    <Delete className={classes.title}/>
                                                </IconButton>
                                            </>
                                        }
                                    />
                                </GridListTile>
                            ))}
                        </GridList>
                        <Dialog
                            fullScreen
                            open={selectedTile !== null}
                            onClose={handleAttachmentClose}
                            TransitionComponent={imagePreviewState.Transition}
                        >
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleAttachmentClose}
                                        aria-label="close"
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                </Toolbar>
                            </AppBar>

                            {selectedTile && (
                                <img src={selectedTile.url} alt={"dummy"} width="400" height="500"/>
                            )}
                        </Dialog>
                    </div>
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        fullWidth
                        id="listingLocation"
                        label="Локация"
                        value={listingLocation}
                        onChange={(e) => setListingLocation(e.target.value)}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={deliveryOnBuyer}
                                name="deliverOnBuyer"
                                color="secondary"
                                readOnly={true}
                                disabled={true}
                            />
                        }
                        label="Доставка за сметка на купувача"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={deliveryOnSeller}
                                name="deliverOnSeller"
                                color="secondary"
                                readOnly={true}
                                disabled={true}
                            />
                        }
                        label="Доставка за сметка на продавача"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={deliveryInPerson}
                                name="deliverInPerson"
                                color="secondary"
                                readOnly={true}
                                disabled={true}
                            />
                        }
                        label="Директна размяна"
                    />
                </Grid>

                {listingRejected &&
                <>
                    <Typography variant="h6" gutterBottom>
                        Детайли за отказване на обявата
                    </Typography>
                    <Divider/>
                    <Grid container item xs={12}>
                        <TextField
                            fullWidth
                            id="rejectedReason"
                            label="Причина за отказ"
                            value={RejectReason[rejectedReason as keyof typeof RejectReason]}
                            disabled={true}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <TextField
                            fullWidth
                            id="rejectedMessage"
                            label="Съобщение за отказ"
                            value={rejectedMessage}
                            disabled={true}
                            variant="outlined"
                        />
                    </Grid>
                </>
                }
                {!listingBookApproved &&
                <>
                    <Typography variant="h6" gutterBottom>
                        Детайли за добавяне на книга
                    </Typography>
                    <Divider/>
                    <Grid container item xs={12}>
                        <TextField
                            fullWidth
                            id="isbn"
                            label="ISBN"
                            value={bookIsbn}
                            onChange={(e) => setBookIsbn(e.target.value)}
                            placeholder="ISBN"
                            disabled={true}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <TextField
                            required
                            id="title"
                            name="Title"
                            label="Заглавие"
                            value={bookTitle}
                            onChange={(e) => setBookTitle(e.target.value)}
                            disabled={listingBookApproved}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <InputLabel id="language">Език</InputLabel>
                        <Select
                            labelId="language"
                            id="lang"
                            label={"Език"}
                            placeholder={"Език"}
                            value={bookLanguage}
                            onChange={handleLanguageChange}
                            fullWidth={true}
                            variant="outlined"
                            disabled={listingBookApproved}
                        >
                            {languages.map(value => {
                                return <MenuItem selected={bookLanguage === value} value={Language[value as keyof typeof Language]}>
                                    {Language[value as keyof typeof Language]}
                                </MenuItem>
                            })}
                        </Select>
                    </Grid>
                    <Grid container item xs={12}>
                        <InputLabel id="language">Корица</InputLabel>
                        <Select
                            labelId="bookCover"
                            id="cover"
                            placeholder={"Корица"}
                            value={bookCover}
                            disabled={listingBookApproved}
                            onChange={handleBookCoverChange}
                            variant="outlined"
                            fullWidth
                        >
                            {covers.map(value => {
                                return <MenuItem selected={bookCover === value} value={BookCover[value as keyof typeof BookCover]}>
                                    {BookCover[value as keyof typeof BookCover]}
                                </MenuItem>
                            })}
                        </Select>
                    </Grid>
                    <Grid container item xs={12}>
                        <Autocomplete
                            id="select-authors"
                            disabled={listingBookApproved}
                            value={bookAuthors}
                            loading={loading}
                            multiple
                            fullWidth
                            options={authorOptions}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                                return newValue.map((author: IAuthor, index: number, authors: Array<IAuthor>) => {
                                    return setBookAuthors(authors)
                                })
                            }}
                            onInputChange={(event, newInputValue) => {
                                handleAuthorSearch(newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Authors"
                                    placeholder="Автори"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <TextField
                            required
                            id="description"
                            multiline
                            name="Description"
                            label="Описание"
                            disabled={listingBookApproved}
                            value={bookDescription}
                            onChange={(e) => setBookDescription(e.target.value)}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <Autocomplete
                            disabled={listingBookApproved}
                            value={bookGenres}
                            multiple
                            id="tags-standard"
                            options={genres}
                            fullWidth
                            getOptionLabel={(option) => option.title}
                            onChange={(event, newValue) => {
                                return newValue.map((value: IGenre, index: number, genres: Array<IGenre>) => {
                                    return setBookGenres(genres)
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Жарнове"
                                    placeholder="Жанрове"
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid container xs={12}>
                        <TextField
                            id="price"
                            name="Price"
                            label="Цена"
                            type="number"
                            value={bookPrice + ""}
                            onChange={(e) => setBookPrice(parseFloat(e.target.value))}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <TextField
                            id="year"
                            name="Year"
                            label="Година на издаване"
                            type="number"
                            value={bookYear + ""}
                            onChange={(e) => setBookYear(parseInt(e.target.value))}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container item xs={12} >
                        <TextField
                            id="pages"
                            name="Pages"
                            label="Брой страници"
                            type="number"
                            value={bookPages + ""}
                            onChange={(e) => setBookPages(parseInt(e.target.value))}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container item xs={12} >
                        <TextField
                            id="publisher"
                            name="Publisher"
                            label="Издателство"
                            value={bookPublisher}
                            onChange={(e) => setBookPublisher(e.target.value)}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <div>
                            <h5 className="text-center">Изберете снимка за корица на книгата</h5>
                            <label htmlFor="upload-button">
                                {savedImageUrl ? (
                                    <img src={savedImageUrl} alt="dummy" width="150" height="200"/>
                                ) : (
                                    <>
                                        <img src={image.toString()} alt="dummy" width="150" height="200"/>
                                    </>
                                )}
                            </label>
                            <input
                                type="file"
                                id="upload-button"
                                style={{display: "none"}}
                                onChange={bookCoverImageChange}
                            />
                        </div>
                    </Grid>
                    <Grid container item xs={12}>
                        <TextField
                            id="listingCount"
                            name="Listings Count"
                            label="Брой обяви"
                            value={listingCount}
                            disabled={true}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                </>
                }

                <Grid item xs={12} container direction="row" justify="flex-end" alignItems={"flex-end"}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleApproval()}
                        disabled={approveButtonDisabled}
                        startIcon={<ThumbUpIcon/>}
                    >
                        Одобри
                    </Button>
                    &nbsp; &nbsp; &nbsp;
                    {!listingBookApproved &&
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => openChangeIsbn()}
                            startIcon={<InsertLinkIcon/>}
                        >
                            Промяна на ISBN
                        </Button>
                        &nbsp; &nbsp; &nbsp;
                    </>
                    }
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => openRejectDialog()}
                        disabled={rejectButtonDisabled}
                        startIcon={<ThumbDownIcon/>}
                    >
                        Откажи
                    </Button>
                    &nbsp; &nbsp; &nbsp;
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => openDeleteDialog()}
                        startIcon={<DeleteIcon />}
                    >
                        Изтрий
                    </Button>
                </Grid>
                <Dialog
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={"lg"}
                    fullWidth={true}
                    open={rejectClicked}
                    onClose={closeRejectDialog}
                >
                    <DialogTitle id="alert-dialog-title">{"Откажи обява"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Изберете причина и съобщение за отказ.
                        </DialogContentText>
                        <Select
                            id="reject-reasons"
                            placeholder={"Причина за отказ"}
                            value={rejectReason}
                            onChange={handleRejectReasonChange}
                            input={<Input fullWidth={true}/>}
                            fullWidth
                            variant="outlined"
                        >
                            {rejectReasons.map(value => {
                                return <MenuItem value={RejectReason[value as keyof typeof RejectReason]}>
                                    {RejectReason[value as keyof typeof RejectReason]}
                                </MenuItem>
                            })}
                        </Select>
                        <br/>
                        <br/>
                        <TextField
                            required
                            id="rejectMessage"
                            multiline
                            name="Message"
                            label="Съобщение за отказ"
                            value={rejectMessage}
                            onChange={(e) => setRejectMessage(e.target.value)}
                            fullWidth
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeRejectDialog} color="primary">
                            Назад
                        </Button>
                        <Button onClick={handleReject} color="primary" autoFocus>
                            Потвърди
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={changeIsbnClicked}
                    onClose={closeChangeIsbn}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Смяна на книга?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Обявата ще бъде асоциирана към друга книга.
                        </DialogContentText>
                        <TextField
                            required
                            id="UpdateIsbn"
                            multiline
                            name="ChangeIsbn"
                            label="Нов ISBN"
                            value={changedIsbn}
                            onChange={(e) => setChangedIsbn(e.target.value)}
                            fullWidth
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeChangeIsbn} color="primary">
                            Отказ
                        </Button>
                        <Button onClick={() => handleUpdateIsbn()} color="primary" autoFocus>
                            Промени
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={deleteClicked}
                    onClose={closeDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Изтриване на обява?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Изтриването на обява е необратим процес.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDeleteDialog} color="primary">
                            Отказ
                        </Button>
                        <Button onClick={() => handleDelete()} color="primary" autoFocus>
                            Изтрий
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Fragment>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        loading: store.listingsState.loading,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IListingsActions>) => {
    return {
        getGenres: () => dispatch(getGenresAction()),
        getListing: (id: number) => dispatch(getListing(id)),
        approveListing: (approveForm: IApproveListingForm) => dispatch(approveListing(approveForm)),
        searchAuthors: (name: string) => dispatch(searchAuthors(name)),
        rejectListing: (listingId: number, reason: string, message: string) => dispatch(rejectListing(listingId, reason, message)),
        deleteListing: (listingId: number) => dispatch(deleteListing(listingId)),
        updateIsbn: (listingId: number, isbn: string) => dispatch(updateListingBook(listingId, isbn))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListingEditor));
