import React, {useEffect} from 'react';
import Layout from "./layout/Layout";
import {BrowserRouter, Switch} from "react-router-dom";
import PrivateRoute from "../utils/PrivateRoute";
import PublicRoute from "../utils/PublicRoute";
import Login from "./login/Login";
import {getDeviceId, storeDeviceId} from "../actions/api";
const Fingerprint2 = require('fingerprintjs2');

const App: React.FC = () => {

    useEffect(() => {
        let fingerprint = getDeviceId()
        if (fingerprint === null) {
            const options = {};
            Fingerprint2.get(options, function (components:[{ key:string, value:string }]) {
                const fingerprint = Fingerprint2.x64hash128(components.map(function (pair:any) { return pair.value; }).join(), 31);
                storeDeviceId(fingerprint)
            })
        }
    })

    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <PublicRoute
                        exact
                        path={"/admin/login"}
                        component={Login}
                    />
                    <PrivateRoute
                        exact
                        path={"/admin"}
                        component={Layout}/>
                    <Layout/>

                </Switch>
            </BrowserRouter>
        </div>
    )
}
export default App;
