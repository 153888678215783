import React, {FC, Fragment, useEffect, useState} from "react";
import {Link, RouteProps} from "react-router-dom";
import {
    Collapse,
    Divider,
    Fab,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Theme
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles} from "@material-ui/core/styles";
import {IAppState, IGenre} from "../../types/types";
import {getGenresAction, IGenreActions, IGotGenresAction} from "../../actions/genre/actions";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) => ({
    fab: {
        position: 'absolute',
        bottom: 18,
        right: 18,
    },
    mainList: {
        maxHeight: '70vh',
        overflow: 'auto',
    },
    items: {
        paddingLeft: theme.spacing(2)
    },
    nested: {
        paddingLeft: theme.spacing(10),
    },
    nestedSecondary: {
        paddingLeft: theme.spacing(30),
    },
}));

interface IProps extends RouteProps {
    getGenres: () => Promise<IGotGenresAction>;
    loading: boolean;
}

const GenresTable: FC<IProps> = ({
                                 getGenres,
                                 loading
                             }) => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [openedItems, setOpenedItems] = useState<Map<number, boolean>>(new Map());
    const handleClick = (id: number) => {
        const tmp = new Map(openedItems)
        const x = openedItems.get(id)
        tmp.set(id, x !== undefined ? !x : true)
        setOpenedItems(tmp)
    };

    const [categoriesByParent, setCategoriesByParent] = useState(new Map<IGenre, Array<IGenre>>())

    useEffect(() => {
        getGenres().then((categories) => {
            let byParents = new Map<IGenre, Array<IGenre>>();
            let parentsById = new Map<number, IGenre>();
            categories.payload.categories.forEach(value => {
                if (!value.parentId) {
                    parentsById.set(value.id, value);
                    byParents.set(value, new Array<IGenre>());
                }
            })
            categories.payload.categories.forEach(value => {
                if (value.parentId) {
                    const parent = parentsById.get(value.parentId)
                    if (parent) {
                        let children = byParents.get(parent)
                        if (children) {
                            children.push(value)
                        }
                    }
                }
            });
            setCategoriesByParent(byParents)
        }).catch(e => {
            console.log(e)
            if (e.response) {
                enqueueSnackbar(e.response.data, {
                    variant: 'error'
                })
            }
        });
    }, [getGenres]);

    return (
        <Fragment>
            {loading && <LinearProgress color="secondary"/>}
            <List className={classes.mainList} key={"categories"}>
                {
                    Array.from(categoriesByParent.keys()).map((parent) => (
                        <Fragment key={`fragment-cat-${parent.id}`}>
                            <ListItem key={`cat-${parent.id}`} onClick={() => handleClick(parent.id)}>
                                {/*
                                // @ts-ignore */}
                                {openedItems.get(parent.id) && categoriesByParent.get(parent)?.length > 0 ?
                                    <ExpandLess/> : <ExpandMore/>}
                                <ListItemText primary={parent.title} className={classes.items}/>
                                <ListItemIcon key={`cat-add-${parent.id}`}>
                                    <IconButton
                                        component={(props) => (
                                            <Link {...props} to={`/admin/genre/${parent.id}/new`}/>)}
                                    >
                                        <AddIcon/>
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemIcon>
                                    <IconButton
                                        component={(props) => (
                                            <Link {...props} to={`/admin/genre/${parent.id}`}/>)}
                                    >
                                        <EditIcon/>
                                    </IconButton>
                                </ListItemIcon>
                            </ListItem>
                            <Collapse in={openedItems.get(parent.id)} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {/*
                                    // @ts-ignore */}
                                    {categoriesByParent.get(parent).map((child) => (
                                        <ListItem key={`cat-${child.id}`} className={classes.nested}>
                                            <ListItemText primary={`${child.title} (${child.books})`}/>
                                            <ListItemSecondaryAction key={`cat-edit-${child.id}`}>
                                                <IconButton
                                                    component={(props) => (
                                                        <Link {...props} to={`/admin/genre/${child.id}`}/>)}
                                                >
                                                    <EditIcon/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                            <Divider light/>
                        </Fragment>
                    ))
                }
            </List>
            <Fab
                color="secondary"
                className={classes.fab}
                component={(props) => (
                    <Link {...props} to={`/admin/genre/new`}/>
                )}
            >
                <AddIcon/>
            </Fab>
        </Fragment>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        loading: store.genresState.loading
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IGenreActions>) => {
    return {
        getGenres: () => dispatch(getGenresAction())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GenresTable);
