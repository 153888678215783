import {Credentials, User} from '../../types/types'
import {ActionCreator, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import axios from "axios";
import {stringify} from "querystring";
import {clearTokens, storeTokens} from "../api";


export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';


export interface LoginAction {
    type: typeof LOGIN
}

export interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS
    payload: {
        authenticated: boolean
        user: User
    }
}

export interface LogoutSuccessAction {
    type: typeof LOGOUT_SUCCESS
    payload: {
        authenticated: boolean
        user: null
    }
}

export interface LoginFailedAction {
    type: typeof LOGIN_FAILED
    payload: {
        message: string
    }
}


export const loginActionCreator: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<LoginSuccessAction | LoginFailedAction>,
    // The type for the data within the last action
    User,
    // The type of the parameter for the nested function
    Credentials,
    // The type of the last action to be dispatched
    LoginSuccessAction | LoginFailedAction>> = (credentials: Credentials) => {
    return async (dispatch: Dispatch) => {
        const loginAction: LoginAction = {
            type: LOGIN
        }
        dispatch(loginAction)
        const body = {
            email: credentials.email,
            password: credentials.password,
            deviceId: localStorage.getItem("deviceId")
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        return await axios.post<User>(
            `${process.env.REACT_APP_API_BASE_URL}/api/user/email/login`, stringify(body), config
        ).then((response) => {
            const status = response.status
            if (status === 200) {
                const loginOk: LoginSuccessAction = {
                    type: LOGIN_SUCCESS,
                    payload: {
                        authenticated: true,
                        user: response.data
                    }
                };
                storeTokens(response.data.accessToken, response.data.refreshToken, response.data)
                return dispatch(loginOk);
            } else {
                clearTokens()
                const loginFailedAction: LoginFailedAction = {
                    type: LOGIN_FAILED,
                    payload: {
                        message: 'Invalid credentials'
                    }
                };
                return dispatch(loginFailedAction);
            }
        }).catch((error) => {
            clearTokens()
            const loginFailedAction: LoginFailedAction = {
                type: LOGIN_FAILED,
                payload: {
                    message: error.toString()
                }
            };
            return dispatch(loginFailedAction);
        })

    };
};

export const logoutActionCreator: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<LogoutSuccessAction>,
    // The type for the data within the last action
    null,
    // The type of the parameter for the nested function
    null,
    // The type of the last action to be dispatched
    LogoutSuccessAction>> = () => {
    return async (dispatch: Dispatch) => {
        clearTokens()
        const logoutOk: LogoutSuccessAction = {
            type: LOGOUT_SUCCESS,
            payload: {
                authenticated: false,
                user: null
            }
        };
        return dispatch(logoutOk);
    };
};

export type IAuthActions =
    | LogoutSuccessAction
    | LoginSuccessAction
    | LoginFailedAction
    | LoginAction
