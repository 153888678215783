import React, {FC, useState} from "react";
import {RouteProps} from "react-router-dom";
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from "mui-datatables";
import {
    Avatar,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography
} from "@material-ui/core";
import {IAppState, IReportDetails, ReportFilter, SearchDirection} from "../../types/types";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import {getReports, IGotReportsAction, IReportsActions} from "../../actions/report/actions";
import {makeStyles} from "@material-ui/core/styles";


interface IProps extends RouteProps {
    getReports: (filter: ReportFilter) => Promise<IGotReportsAction>
    loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    }
}));


const ReportTable: FC<IProps> = ({
                                     getReports,
                                     loading
                                 }) => {
    const classes = useStyles();
    const defaultAvatar = process.env.PUBLIC_URL + '/default-account-avatar.svg';
    const [reports, setReports] = useState<IReportDetails[]>([]);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [, setDirection] = useState<SearchDirection>(SearchDirection.NEXT);

    const search = (newPage: number, rowsNumber: number, reports: Array<IReportDetails>) => {
        let filter = calculateFilter(newPage, rowsNumber, reports)
        setDirection(filter.direction);
        setPage(newPage);
        setRowsPerPage(rowsNumber);
        getReports(filter).then(r => {
            setReports(r.payload.reports)
            setCount(r.payload.count)
        }).catch(e => console.log(e))
    }

    const calculateFilter = (newPage: number, rowsNumber: number, reports: Array<IReportDetails>): ReportFilter => {
        if (rowsPerPage !== rowsNumber) {
            return {
                lastSeenId: null,
                size: rowsNumber,
                direction: SearchDirection.NEXT
            }
        }

        let direction: SearchDirection
        let lastSeenId: number | null = null
        if (rowsNumber === rowsPerPage) {
            if (page <= newPage) {
                direction = SearchDirection.NEXT
                lastSeenId = getLastSeenId(SearchDirection.NEXT, reports)
            } else {
                direction = SearchDirection.PREVIOUS
                lastSeenId = getLastSeenId(SearchDirection.PREVIOUS, reports)
            }
        } else {
            direction = SearchDirection.NEXT
        }

        return {
            lastSeenId: lastSeenId,
            size: rowsNumber,
            direction: direction
        }
    }

    const getLastSeenId = (direction: SearchDirection, reports: Array<IReportDetails>): number | null => {
        if (reports.length === 0) {
            return null
        }
        if (direction === SearchDirection.NEXT) {
            return reports[reports.length - 1].id
        } else {
            return reports[0].id
        }
    }

    const handleRowsPerPageUpdate = (rowsNumber: number) => {
        setRowsPerPage(rowsNumber);
        return search(0, rowsNumber, reports);
    }

    const options: MUIDataTableOptions = {
        filter: true,
        fixedSelectColumn: false,
        selectableRows: 'none',
        viewColumns: false,
        responsive: "vertical",
        serverSide: true,
        count: count,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 50, 100],
        download: false,
        print: false,
        search: false,
        sort: false,
        expandableRowsOnClick: true,
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            const report = reports[rowMeta.rowIndex]
            const style = {
                display: 'inline-block',
                paddingRight: '15px',
                align: 'center',
                alignItems: 'center'
            };
            let reportedCell;
            if (report.reportedProfile) {
                let avatarUrl;
                if (report.reportedProfile.avatarUrl) {
                    avatarUrl = report.reportedProfile.avatarUrl
                } else {
                    avatarUrl = defaultAvatar
                }
                reportedCell = <div>
                    <span style={style}><Avatar
                        className={classes.small}
                        alt={`Avatar${report.reportedProfile.id}`}
                        src={`${avatarUrl}`}
                    />
                        </span>
                    <span style={style}>{report.reportedProfile.nickname}</span>
                </div>
            } else {
                reportedCell= <div>
                    <span style={style}>
                        <Avatar
                            className={classes.small}
                            alt={`Avatar${report.reportedListing?.listingId}`}
                            src={`${report.reportedListing?.frontCover}`}
                        />
                    </span>
                    <span style={style}>Обява: {report.reportedListing?.listingId}</span>
                    <span style={style}>Книга: {report.reportedListing?.bookTitle}</span>
                </div>
            }

            return (
                <React.Fragment>
                    <tr>
                        <td colSpan={3}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{minWidth: "800px"}} align="left">Съобщение</TableCell>
                                            <TableCell style={{minWidth: "800px"}}
                                                       align="left">{report.reportedListing ? "Докладвана обява" : "Докладван потребител"}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                {report.message}
                                            </TableCell>
                                            <TableCell>
                                                {reportedCell}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </tr>
                </React.Fragment>

            );
        },
        onChangePage: currentPage => search(currentPage, rowsPerPage, reports),
        onChangeRowsPerPage: rowsNumber => handleRowsPerPageUpdate(rowsNumber),
        onTableInit: () => search(0, rowsPerPage, reports)
    };
    const columns: MUIDataTableColumnDef[] = [
        {
            name: "reporter",
            label: "Докладвал",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (reporter: any) => {
                    const entries = new Map(Object.entries(reporter))
                    const id = Number(entries.get("id"))
                    const nickname = String(entries.get("nickname"))
                    let avatarUrl;
                    if (entries.get("avatarUrl")) {
                        avatarUrl = String(entries.get("avatarUrl"))
                    } else {
                        avatarUrl = defaultAvatar
                    }
                    const style = {
                        display: 'inline-block',
                        paddingRight: '15px',
                        align: 'center',
                        alignItems: 'center'
                    };
                    return <div>
                        <span style={style}><Avatar
                            className={classes.small}
                            alt={`Avatar${id + 1}`}
                            src={`${avatarUrl}`}
                        />
                        </span>
                        <span style={style}>{nickname}</span>
                    </div>
                }
            },
        },
        {
            name: "createdAt",
            label: "Докладван на",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (createdAt: number) => {
                    return new Date(createdAt).toLocaleString();
                }
            }
        }
    ]

    return (
        <div>
            <MUIDataTable
                title={
                    <Typography variant="h6">
                        Доклади
                        {loading && <LinearProgress color="secondary"/>}
                    </Typography>
                }
                data={reports}
                columns={columns}
                options={options}
            />
        </div>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        reports: store.reportsState.reports,
        count: store.reportsState.count,
        loading: store.reportsState.loading,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IReportsActions>) => {
    return {
        getReports: (filter: ReportFilter) => dispatch(getReports(filter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportTable);
