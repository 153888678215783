import {IAuthState} from '../../types/types'
import {IAuthActions, LOGIN, LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT_SUCCESS} from "../../actions/auth/actions";
import {Reducer} from "redux";

const initialAuthState: IAuthState = {
    authenticated: false,
    user: null,
    loading: false
};

// @ts-ignore
export const authReducer: Reducer<IAuthState, IAuthActions> = (
    state = initialAuthState,
    action
) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                loading: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                authenticated: action.payload.authenticated,
                user: action.payload.user
            };
        case LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                authenticated: false,
                user: null
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                authenticated: false,
                user: null
            };
        default:
            return state;
    }
}