import {Reducer} from "redux";
import {IAuthorState} from "../../types/types";
import {
    APPROVED_AUTHOR,
    APPROVING_AUTHOR,
    FOUND_AUTHORS,
    GETTING_AUTHOR,
    GETTING_AUTHORS,
    GOT_AUTHOR,
    GOT_AUTHORS,
    IAuthorActions,
    SAVED_AUTHOR,
    SAVING_AUTHOR,
    SEARCHING_AUTHORS
} from "../../actions/author/actions";

const initialAuthorsState: IAuthorState = {
    authors: [],
    count: 0,
    foundAuthors: [],
    loading: false
};

// @ts-ignore
export const authorsReducer: Reducer<IAuthorState, IAuthorActions> = (
    state = initialAuthorsState,
    action
) => {

    switch (action.type) {
        case GETTING_AUTHORS: {
            return {
                ...state,
                loading: true
            };
        }
        case GOT_AUTHORS: {
            return {
                ...state,
                authors: action.payload.authors,
                count: action.payload.count,
                loading: false
            };
        }
        case SAVING_AUTHOR: {
            return {
                ...state,
                loading: true
            };
        }

        case SAVED_AUTHOR: {
            return {
                ...state,
                loading: false
            };
        }
        case GETTING_AUTHOR: {
            return {
                ...state,
                loading: true
            };
        }
        case GOT_AUTHOR: {
            return {
                ...state,
                loading: false
            };
        }
        case SEARCHING_AUTHORS: {
            return {
                ...state,
                loading: true
            };
        }
        case FOUND_AUTHORS: {
            return {
                ...state,
                foundAuthors: action.payload.authors,
                count: action.payload.count,
                loading: false
            };
        }
        case APPROVING_AUTHOR: {
            return {
                ...state,
                loading: true
            };
        }
        case APPROVED_AUTHOR: {
            return {
                ...state,
                loading: false
            };
        }
        default:
            return state;
    }

};

