import {Reducer} from "redux";
import {IReportState} from "../../types/types";
import {GETTING_REPORTS, GOT_REPORTS} from "../../actions/report/actions";

const initialReportsState: IReportState = {
    reports: [],
    count: 0,
    loading: false
};

// @ts-ignore
export const reportsReducer: Reducer<IReportState, IReportsActions> = (
    state = initialReportsState,
    action
) => {
    switch (action.type) {
        case GETTING_REPORTS: {
            return {
                ...state,
                loading: true
            };
        }
        case GOT_REPORTS: {
            return {
                ...state,
                reports: action.payload.reports,
                count: action.payload.count,
                loading: false
            };
        }
        default:
            return state;
    }
};

