import {Reducer} from "redux";
import {IUsersState} from "../../types/types";
import {
    GETTING_USER,
    GETTING_USERS,
    GOT_USER,
    GOT_USERS,
    UPDATED_DISABLE_FLAG,
    UPDATING_DISABLE_FLAG
} from "../../actions/user/actions";

const initialUsersState: IUsersState = {
    users: [],
    count: 0,
    loading: false
};

// @ts-ignore
export const usersReducer: Reducer<IUsersState, IUsersActions> = (
    state = initialUsersState,
    action
) => {
    switch (action.type) {
        case GETTING_USERS: {
            return {
                ...state,
                loading: true
            };
        }
        case GOT_USERS: {
            return {
                ...state,
                users: action.payload.users,
                count: action.payload.count,
                loading: false
            };
        }
        case GETTING_USER: {
            return {
                ...state,
                loading: true
            };
        }
        case GOT_USER: {
            return {
                ...state,
                user: action.payload.user,
                loading: false
            };
        }
        case UPDATING_DISABLE_FLAG: {
            return {
                ...state,
                loading: true
            };
        }
        case UPDATED_DISABLE_FLAG: {
            return {
                ...state,
                loading: false
            };
        }
        default:
            return state;
    }
};

