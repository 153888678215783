import React, {FC, useEffect, useState} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {
    IAuthActions,
    LOGIN_FAILED,
    loginActionCreator,
    LoginFailedAction,
    LoginSuccessAction
} from '../../actions/auth/actions'
import {Credentials, IAppState} from "../../types/types";
import {ThunkDispatch} from "redux-thunk";
import {useHistory} from 'react-router-dom';
import {CircularProgress} from "@material-ui/core";
import {ReactComponent as Logo} from '../../images/knijko-wordmark.svg';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

interface IProps {
    login: (credentials: Credentials) => Promise<LoginSuccessAction | LoginFailedAction>
    loading: boolean;
}


const Login: FC<IProps> = ({
                               login,
                               loading
                           }) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const history = useHistory();

    const [password, setPassword] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [helperText, setHelperText] = useState('');
    const [error, setError] = useState(false);

    const handleLogin = () => {
        login({
            email: email,
            password: password
        }).then(
            (response: LoginSuccessAction | LoginFailedAction) => {
                if (response.type === LOGIN_FAILED) {
                    setError(true)
                    setHelperText('Invalid credentials')
                } else {
                    setError(false)
                    setHelperText('')
                    history.push("/admin")
                }
            }
        ).catch((error) => {
            console.log("Got error during login", error)
            setError(true)
            setHelperText('Server error')
        })
    };

    const handleKeyPress = (e: any) => {
        if (e.keyCode === 13 || e.which === 13) {
            isButtonDisabled || handleLogin();
        }
    };


    useEffect(() => {
        if (email.trim() && password.trim()) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [email, password]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <div style={{
                    height: '100px',
                    width: '200px'
                }} className="size">
                    <Logo/>
                </div>
                <form className={classes.form} noValidate>
                    <TextField
                        error={error}
                        fullWidth
                        id="username"
                        type="email"
                        label="Username"
                        placeholder="Username"
                        margin="normal"
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e)}
                    />
                    <TextField
                        error={error}
                        fullWidth
                        id="password"
                        type="password"
                        label="Password"
                        placeholder="Password"
                        margin="normal"
                        helperText={helperText}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e)}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => handleLogin()}
                        disabled={isButtonDisabled}
                    >
                        Sign In
                    </Button>
                </form>
                {loading && <div><CircularProgress color="secondary"/></div>}
            </div>
        </Container>
    )
}
const mapStateToProps = (store: IAppState) => {
    return {
        authenticated: store.authState.authenticated,
        user: store.authState.user,
        loading: store.authState.loading
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IAuthActions>) => {
    return {
        login: (credentials: Credentials) => dispatch(loginActionCreator(credentials))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
