import React, {FC, useEffect, useState} from "react";
import {match, RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import {Button, Grid, TextField} from "@material-ui/core";
import {IAppState} from "../../types/types";
import {
    getCategoryByIdAction,
    IGenreActions,
    IGotGenreByIdAction,
    ISavedGenreAction,
    saveGenreAction
} from "../../actions/genre/actions";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSnackbar } from 'notistack';


interface CategoryEditorParams {
    id: string;
}

interface IProps extends RouteComponentProps<CategoryEditorParams> {
    fetchCategory: (id: number) => Promise<IGotGenreByIdAction>;
    postCategory: (id: number | null, parentId: number | null, title: string) => Promise<ISavedGenreAction>;
    match: match<CategoryEditorParams>;
    loading: boolean;
}

const GenreEditor: FC<IProps> = ({
                                     fetchCategory,
                                     postCategory,
                                     match,
                                     loading
                                 }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [id, setId] = useState<number | null>(null)
    const [parentId, setParentId] = useState<number | null>(null)
    const [title, setTitle] = useState<string>("")

    useEffect(() => {
        if (match.params.id === "new") {

        } else if (match.path.endsWith("new") && match.params.id !== "new") {
            setParentId(+match.params.id)
        } else {
            fetchCategory(+match.params.id).then((value => {
                setId(value.payload.category.id)
                setParentId(value.payload.category.parentId)
                setTitle(value.payload.category.title)
            })).catch(e => {
                console.log(e)
                if (e.response) {
                    enqueueSnackbar(e.response.data, {
                        variant: 'error',
                        style: { whiteSpace: 'pre-line' }
                    })
                }
                history.push("/admin/genre")
            })
        }
    }, [fetchCategory, match.params.id, match.path]);

    const handleSubmit = () => {
        postCategory(id, parentId, title)
            .then(() => {
                enqueueSnackbar("Операцията е изпълена успешно", {
                    variant: 'success'
                })
                history.push("/admin/genre")
            }).catch(e => {
                if (e.response) {
                    enqueueSnackbar(e.response.data, {
                        variant: 'error',
                        style: { whiteSpace: 'pre-line' }
                    })
                }
            })
    };

    return (

        <Grid container spacing={4}>
            {(loading) && <LinearProgress color="secondary"/>}
            <Grid item xs={12}>
                <TextField
                    id="title"
                    label="Име"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    variant="outlined"
                />
            </Grid>
            <Grid item xl={8} xs={6}/>
            <Grid item xl={4} xs={6} container justify="flex-end">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                >
                    Запази
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.goBack()}
                >
                    Назад
                </Button>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        loading: store.genresState.loading,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IGenreActions>) => {
    return {
        fetchCategory: (id: number) => dispatch(getCategoryByIdAction(id)),
        postCategory: (id: number | null, parentId: number | null, title: string) => dispatch(saveGenreAction(id, parentId, title))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GenreEditor));
