import {IGenre} from '../../types/types'
import {ThunkAction} from "redux-thunk";
import {AxiosResponse} from 'axios'
import api from '../api'
import {ActionCreator, Dispatch} from "redux";

export const GETTING_GENRES = "GETTING_GENRES";
export const GOT_GENRES = "GOT_GENRES";
export const GOT_GENRE_BY_ID = "GOT_GENRE_BY_ID";
export const SAVING_GENRE = "SAVING_GENRE";
export const SAVED_GENRE = "SAVED_GENRE";


export interface IGettingGenresAction {
    type: typeof GETTING_GENRES
}

export interface IGotGenresAction {
    type: typeof GOT_GENRES
    payload: {
        categories: IGenre[]
    }
}

export interface IGotGenreByIdAction {
    type: typeof GOT_GENRE_BY_ID
    payload: {
        category: IGenre
    }
}

export interface ISavingGenreAction {
    type: typeof SAVING_GENRE
}

export interface ISavedGenreAction {
    type: typeof SAVED_GENRE
    payload: {
        category: IGenre
    }
}

export const getGenresAction: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IGotGenresAction>,
    // The type for the data within the last action
    IGenre[],
    // The type of the parameter for the nested function
    null,
    // The type of the last action to be dispatched
    IGotGenresAction>> = () => {
    return async (dispatch: Dispatch) => {
        const gettingCategoriesAction: IGettingGenresAction = {
            type: GETTING_GENRES
        };
        dispatch(gettingCategoriesAction);

        const axiosResponse = await api.get<Array<IGenre>>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/genre`)

        const gotCategoriesAction: IGotGenresAction = {
            payload: {
                categories: axiosResponse.data
            },
            type: GOT_GENRES
        };
        return dispatch(gotCategoriesAction);
    };
};

export const getCategoryByIdAction: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IGotGenreByIdAction>,
    // The type for the data within the last action
    IGenre,
    // The type of the parameter for the nested function
    number,
    // The type of the last action to be dispatched
    IGotGenreByIdAction>> = (id: number) => {
    return async (dispatch: Dispatch) => {

        const axiosResponse = await api.get<IGenre>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/genre/${id}`)
        const gotCategoryByIdAction: IGotGenreByIdAction = {
            payload: {
                category: axiosResponse.data
            },
            type: GOT_GENRE_BY_ID
        };
        return dispatch(gotCategoryByIdAction);
    };
};


export const saveGenreAction: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<ISavedGenreAction>,
    // The type for the data within the last action
    IGenre,
    // The type of the parameter for the nested function
    IGenre,
    // The type of the last action to be dispatched
    ISavedGenreAction>> = (id: number | null, parentId: number | null, title: string) => {
    return async (dispatch: Dispatch) => {
        const savingCategoryAction: ISavingGenreAction = {
            type: SAVING_GENRE
        };
        dispatch(savingCategoryAction);

        let resp: AxiosResponse<IGenre>;
        if (id) {
            resp = await api.post<IGenre>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/genre/${id}`, {
                id: id,
                parentId: parentId,
                title: title
            });
        } else {
            resp = await api.post<IGenre>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/genre`, {
                parentId: parentId,
                title: title
            });
        }

        const savedCategoryAction: ISavedGenreAction = {
            type: SAVED_GENRE,
            payload: {
                category: resp.data
            }
        };
        return dispatch(savedCategoryAction);
    };
};

export type IGenreActions =
    | IGettingGenresAction
    | IGotGenresAction
    | IGotGenreByIdAction
    | ISavingGenreAction
    | ISavedGenreAction;
