import ReactDOM from 'react-dom';
import React from 'react';
import App from './components/App';
import {Provider} from 'react-redux';
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from './store/store';
import { SnackbarProvider } from 'notistack';


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <SnackbarProvider>
                <App/>
            </SnackbarProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);
