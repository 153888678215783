import React, {FC, useState} from "react";
import {RouteProps} from "react-router-dom";
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from "mui-datatables";
import {Avatar, Theme, Typography} from "@material-ui/core";
import {FeedbackFilter, IAppState, IFeedbackDetails, SearchDirection} from "../../types/types";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import {makeStyles} from "@material-ui/core/styles";
import {getFeedbacks, IFeedbacksActions, IGotFeedbacksAction} from "../../actions/feedback/actions";


interface IProps extends RouteProps {
    getFeedbacks: (filter: FeedbackFilter) => Promise<IGotFeedbacksAction>
    loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    }
}));


const FeedbackTable: FC<IProps> = ({
                                       getFeedbacks,
                                       loading
                                 }) => {
    const classes = useStyles();
    const defaultAvatar = process.env.PUBLIC_URL + '/default-account-avatar.svg';
    const [feedbacks, setFeedbacks] = useState<IFeedbackDetails[]>([]);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [, setDirection] = useState<SearchDirection>(SearchDirection.NEXT);

    const search = (newPage: number, rowsNumber: number, reports: Array<IFeedbackDetails>) => {
        let filter = calculateFilter(newPage, rowsNumber, reports)
        setDirection(filter.direction);
        setPage(newPage);
        setRowsPerPage(rowsNumber);
        getFeedbacks(filter).then(r => {
            setFeedbacks(r.payload.feedbacks)
            setCount(r.payload.count)
        }).catch(e => console.log(e))
    }

    const calculateFilter = (newPage: number, rowsNumber: number, reports: Array<IFeedbackDetails>): FeedbackFilter => {
        if (rowsPerPage !== rowsNumber) {
            return {
                lastSeenId: null,
                size: rowsNumber,
                direction: SearchDirection.NEXT
            }
        }

        let direction: SearchDirection
        let lastSeenId: number | null = null
        if (rowsNumber === rowsPerPage) {
            if (page <= newPage) {
                direction = SearchDirection.NEXT
                lastSeenId = getLastSeenId(SearchDirection.NEXT, reports)
            } else {
                direction = SearchDirection.PREVIOUS
                lastSeenId = getLastSeenId(SearchDirection.PREVIOUS, reports)
            }
        } else {
            direction = SearchDirection.NEXT
        }

        return {
            lastSeenId: lastSeenId,
            size: rowsNumber,
            direction: direction
        }
    }

    const getLastSeenId = (direction: SearchDirection, reports: Array<IFeedbackDetails>): number | null => {
        if (reports.length === 0) {
            return null
        }
        if (direction === SearchDirection.NEXT) {
            return reports[reports.length - 1].id
        } else {
            return reports[0].id
        }
    }

    const handleRowsPerPageUpdate = (rowsNumber: number) => {
        setRowsPerPage(rowsNumber);
        return search(0, rowsNumber, feedbacks);
    }

    const options: MUIDataTableOptions = {
        filter: true,
        fixedSelectColumn: false,
        selectableRows: 'none',
        viewColumns: false,
        responsive: "vertical",
        serverSide: true,
        count: count,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: [10, 50, 100],
        download: false,
        print: false,
        search: false,
        sort: false,
        expandableRowsOnClick: false,
        expandableRows: false,
        onChangePage: currentPage => search(currentPage, rowsPerPage, feedbacks),
        onChangeRowsPerPage: rowsNumber => handleRowsPerPageUpdate(rowsNumber),
        onTableInit: () => search(0, rowsPerPage, feedbacks)
    };
    const columns: MUIDataTableColumnDef[] = [
        {
            name: "reporter",
            label: "Изпратил",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (reporter: any | undefined) => {
                    const style = {
                        display: 'inline-block',
                        paddingRight: '15px',
                        align: 'center',
                        alignItems: 'center'
                    };
                    if (reporter) {
                        const entries = new Map(Object.entries(reporter))
                        const id = Number(entries.get("id"))
                        const nickname = String(entries.get("nickname"))
                        const email = String(entries.get("email"))
                        let avatarUrl;
                        if (entries.get("avatarUrl")) {
                            avatarUrl = String(entries.get("avatarUrl"))
                        } else {
                            avatarUrl = defaultAvatar
                        }

                        return <div>
                            <span style={style}><Avatar
                                className={classes.small}
                                alt={`Avatar${id + 1}`}
                                src={`${avatarUrl}`}
                            />
                            </span>
                            <span style={style}>{nickname}</span>
                            <span style={style}>{email}</span>
                        </div>

                    } else {
                        return <div>
                            <span style={style}><Avatar
                                className={classes.small}
                                alt={"Avatar"}
                                src={`${defaultAvatar}`}
                            />
                            </span>
                            <span style={style}>Неизвестен</span>
                        </div>
                    }
                }
            },
        },
        {
            name: "message",
            label: "Обратна връзка",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "createdAt",
            label: "Изпратена на",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (createdAt: number) => {
                    return new Date(createdAt).toLocaleString();
                }
            }
        }
    ]

    return (
        <div>
            <MUIDataTable
                title={
                    <Typography variant="h6">
                        Обратна връзка
                        {loading && <LinearProgress color="secondary"/>}
                    </Typography>
                }
                data={feedbacks}
                columns={columns}
                options={options}
            />
        </div>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        feedbacks: store.feedbacksState.feedbacks,
        count: store.feedbacksState.count,
        loading: store.feedbacksState.loading,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IFeedbacksActions>) => {
    return {
        getFeedbacks: (filter: FeedbackFilter) => dispatch(getFeedbacks(filter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackTable);
