import {AuthorFilter, AuthorsPage, IAuthor, IBook} from '../../types/types'
import {ThunkAction} from "redux-thunk";
import api from '../api'
import {ActionCreator, Dispatch} from "redux";
import {stringify} from "querystring";

export const GETTING_AUTHORS = "GETTING_AUTHORS";
export const GOT_AUTHORS = "GOT_AUTHORS";
export const GETTING_AUTHOR = "GETTING_AUTHOR";
export const GOT_AUTHOR = "GOT_AUTHOR";
export const SAVING_AUTHOR = "SAVING_AUTHOR";
export const SAVED_AUTHOR = "SAVED_AUTHOR";
export const SEARCHING_AUTHORS = "SEARCHING_AUTHORS";
export const FOUND_AUTHORS = "FOUND_AUTHORS";
export const APPROVING_AUTHOR = "APPROVING_AUTHOR";
export const APPROVED_AUTHOR = "APPROVED_AUTHOR";


export interface IGettingAuthorsAction {
    type: typeof GETTING_AUTHORS
}

export interface IGotAuthorsAction {
    type: typeof GOT_AUTHORS
    payload: {
        authors: IAuthor[]
        count: number
    }
}

export interface IGettingAuthorAction {
    type: typeof GETTING_AUTHOR
}

export interface IGotAuthorAction {
    type: typeof GOT_AUTHOR
    payload: {
        author: IAuthor
    }
}

export interface ISavingAuthorAction {
    type: typeof SAVING_AUTHOR
}

export interface ISavedAuthorAction {
    type: typeof SAVED_AUTHOR,
    payload: {
        author: IAuthor
    }
}

export interface ISearchingAuthors {
    type: typeof SEARCHING_AUTHORS
}

export interface IFoundAuthors {
    type: typeof FOUND_AUTHORS,
    payload: {
        authors: IAuthor[],
        count: number
    }
}

export interface IApprovingAuthor {
    type: typeof APPROVING_AUTHOR
}

export interface IApprovedAuthor {
    type: typeof APPROVED_AUTHOR
}

export const getAuthors: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IGotAuthorsAction>,
    // The type for the data within the last action
    IAuthor[],
    // The type of the parameter for the nested function
    AuthorFilter,
    // The type of the last action to be dispatched
    IGotAuthorsAction>> = (filter: AuthorFilter) => {
    return async (dispatch: Dispatch) => {

        const gettingAuthorsAction: IGettingAuthorsAction = {
            type: GETTING_AUTHORS
        };
        dispatch(gettingAuthorsAction);

        let params = `size=${filter.size}&direction=${filter.direction}`
        if (filter.lastSeenId != null) {
            params = params + `&lastSeenId=${filter.lastSeenId}`
        }
        if (filter.term != null) {
            params = params + `&term=${filter.term}`
        }
        if (filter.approved != null) {
            params = params + `&approved=${filter.approved}`
        }

        const axiosResponse = await api.get<AuthorsPage>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/author?${params}`)
        const iGotAuthorsAction: IGotAuthorsAction = {
            payload: {
                authors: axiosResponse.data.authors,
                count: axiosResponse.data.count
            },
            type: GOT_AUTHORS
        };
        return dispatch(iGotAuthorsAction);
    };
};

export const getAuthor: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IGotAuthorAction>,
    // The type for the data within the last action
    IBook,
    // The type of the parameter for the nested function
    number,
    // The type of the last action to be dispatched
    IGotAuthorAction>> = (authorId: number) => {
    return async (dispatch: Dispatch) => {

        const gettingAuthorAction: IGettingAuthorAction = {
            type: GETTING_AUTHOR
        };
        dispatch(gettingAuthorAction);

        const response = await api.get<IAuthor>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/author/${authorId}`)
        const gotAuthorAction: IGotAuthorAction = {
            payload: {
                author: response.data
            },
            type: GOT_AUTHOR
        };
        return dispatch(gotAuthorAction);
    };
};

export const saveAuthor: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<ISavedAuthorAction>,
    // The type for the data within the last action
    IAuthor,
    // The type of the parameter for the nested function
    IAuthor,
    // The type of the last action to be dispatched
    ISavedAuthorAction>> = (author: IAuthor) => {
    return async (dispatch: Dispatch) => {
        const savingAuthorAction: ISavingAuthorAction = {
            type: SAVING_AUTHOR
        };
        dispatch(savingAuthorAction);

        const body = {
            name: author.name
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        let response
        if (author.id !== null) {
            response = await api.post<IAuthor>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/author/${author.id}`, author);
        } else {
            response = await api.post<IAuthor>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/author`, stringify(body), config);
        }

        const savedAuthorAction: ISavedAuthorAction = {
            type: SAVED_AUTHOR,
            payload: {
                author: response.data
            }
        };
        return dispatch(savedAuthorAction);
    };
};

export const approveAuthor: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IApprovedAuthor>,
    // The type for the data within the last action
    null,
    // The type of the parameter for the nested function
    number,
    // The type of the last action to be dispatched
    IApprovedAuthor>> = (id: number) => {
    return async (dispatch: Dispatch) => {
        const approving: IApprovingAuthor = {
            type: APPROVING_AUTHOR
        };
        dispatch(approving);

        await api.post<AuthorsPage>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/author/approve/${id}`);

        const found: IApprovedAuthor = {
            type: APPROVED_AUTHOR
        };
        return dispatch(found);
    };
};

export const searchAuthors: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IFoundAuthors>,
    // The type for the data within the last action
    IAuthor,
    // The type of the parameter for the nested function
    string,
    // The type of the last action to be dispatched
    IFoundAuthors>> = (name: string) => {
    return async (dispatch: Dispatch) => {
        const searching: ISearchingAuthors = {
            type: SEARCHING_AUTHORS
        };
        dispatch(searching);

        const body = {
            name: name
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        let response = await api.post<AuthorsPage>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/author/search`, stringify(body), config);

        const found: IFoundAuthors = {
            type: FOUND_AUTHORS,
            payload: {
                authors: response.data.authors,
                count: response.data.count
            }
        };
        return dispatch(found);
    };
};

export type IAuthorActions =
    | IGettingAuthorsAction
    | IGotAuthorsAction
    | IGettingAuthorAction
    | IGotAuthorAction
    | ISavingAuthorAction
    | ISavedAuthorAction
    | ISearchingAuthors
    | IFoundAuthors
    | IApprovingAuthor
    | IApprovedAuthor;
