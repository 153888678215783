import {Reducer} from "redux";
import {IFeedbackState} from "../../types/types";
import {GETTING_FEEDBACKS, GOT_FEEDBACKS, IFeedbacksActions} from "../../actions/feedback/actions";

const initialFeedbacksState: IFeedbackState = {
    feedbacks: [],
    count: 0,
    loading: false
};

// @ts-ignore
export const feedbacksReducer: Reducer<IFeedbackState, IFeedbacksActions> = (
    state = initialFeedbacksState,
    action
) => {
    switch (action.type) {
        case GETTING_FEEDBACKS: {
            return {
                ...state,
                loading: true
            };
        }
        case GOT_FEEDBACKS: {
            return {
                ...state,
                feedbacks: action.payload.feedbacks,
                count: action.payload.count,
                loading: false
            };
        }
        default:
            return state;
    }
};

