import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ListIcon from '@material-ui/icons/List';
import ReportIcon from '@material-ui/icons/Report';
import GenresTable from "../components/genre/GenresTable";
import BooksTable from "../components/book/BooksTable";
import {FC} from "react";
import {SvgIconTypeMap} from "@material-ui/core";
import {OverridableComponent} from "@material-ui/core/OverridableComponent";
import AuthorsTable from "../components/author/AuthorsTable";
import ListingsTable from "../components/listing/ListingsTable";
import ReportTable from "../components/report/ReportTable";
import FeedbackIcon from '@material-ui/icons/Feedback';
import FeedbackTable from "../components/feedback/FeedbackTable";
import PersonIcon from '@material-ui/icons/Person';
import UsersTable from "../components/user/UsersTable";

class LayoutRoute {
    path: string;
    label: string;
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    component: FC;

    constructor(path: string,
                label: string,
                icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
                component: FC) {
        this.path = path;
        this.label = label;
        this.icon = icon;
        this.component = component
    }
}

export const LayoutRoutes: Array<LayoutRoute> = [
    new LayoutRoute("/admin/genre", "Genres", LibraryBooksIcon, GenresTable),
    new LayoutRoute("/admin/author", "Authors", PermContactCalendarIcon, AuthorsTable),
    new LayoutRoute("/admin/book", "Books", MenuBookIcon, BooksTable),
    new LayoutRoute("/admin/listing", "Listings", ListIcon, ListingsTable),
    new LayoutRoute("/admin/report", "Reports", ReportIcon, ReportTable),
    new LayoutRoute("/admin/feedback", "Feedbacks", FeedbackIcon, FeedbackTable),
    new LayoutRoute("/admin/user", "Users", PersonIcon, UsersTable)
];
