import React, {ChangeEvent, FC, Fragment, useEffect, useState} from "react";
import {match, RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import {
    Button,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {BookCover, IAppState, IAuthor, IBookForm, IGenre, Language} from "../../types/types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {
    deleteBook,
    getBook,
    IBooksActions,
    IDeletedBook,
    IGotBookAction,
    ISavedBookAction,
    saveBook
} from "../../actions/book/actions";
import {getGenresAction, IGotGenresAction} from "../../actions/genre/actions";
import {IFoundAuthors, searchAuthors} from "../../actions/author/actions";
import LinearProgress from "@material-ui/core/LinearProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from 'notistack';

interface BookEditorParams {
    id: string;
}

interface IProps extends RouteComponentProps<BookEditorParams> {
    getGenres: () => Promise<IGotGenresAction>;
    getBook: (id: number) => Promise<IGotBookAction>;
    saveBook: (bookForm: IBookForm) => Promise<ISavedBookAction>;
    deleteBook: (id: number) => Promise<IDeletedBook>;
    searchAuthors: (name: string) => Promise<IFoundAuthors>;
    match: match<BookEditorParams>;
    loading: boolean;
}


const BookEditor: FC<IProps> = ({
                                    getGenres,
                                    getBook,
                                    saveBook,
                                    deleteBook,
                                    searchAuthors,
                                    match,
                                    loading
                                }) => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const covers = Object.keys(BookCover).map(value => value)
    const languages = Object.keys(Language).map(value => value)
    const [bookId, setBookId] = useState<number | null>(null)
    const [isbn, setIsbn] = useState("")
    const [title, setTitle] = useState("")
    const [listingCount, setListingCount] = useState(0)
    const [minListingPrice, setMinListingPrice] = useState<number | null>(null)
    const [description, setDescription] = useState("")
    const [bookGenres, setBookGenres] = useState<Array<IGenre>>([])
    const [genres, setGenres] = useState<Array<IGenre>>([])
    const [genresById, setGenresById] = useState<Map<number, IGenre>>(new Map<number, IGenre>())
    const [language, setLanguage] = useState<string>("")
    const [bookCover, setBookCover] = useState<string>("")
    const [price, setPrice] = useState<string>("")
    const [year, setYear] = useState<string>("")
    const [pages, setPages] = useState<string>("")
    const [publisher, setPublisher] = useState<string>("")
    const [coverImage, setCoverImage] = useState("")
    const [coverImageContentType, setCoverImageContentType] = useState("")
    const [base64, setBase64] = useState<string | ArrayBuffer | null>(null)
    const [savedImageUrl, setSavedImageUrl] = useState<string | null>(null)
    const [isEdit, setIsEdit] = useState(false);
    const [image, setImage] = useState<string | ArrayBuffer>(process.env.PUBLIC_URL + '/default-book-cover.svg')

    const [bookAuthors, setBookAuthors] = useState<Array<IAuthor>>([]);
    const [authorOptions, setAuthorOptions] = useState<Array<IAuthor>>([]);

    const [deleteClicked, setDeleteClicked] = useState<boolean>(false);

    const imageChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        let files = event.target.files
        if (files) {
            let file = files.item(0);
            if (file) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    if (file && reader.result) {
                        setCoverImage(file.name)
                        setCoverImageContentType(file.type)
                        setBase64(reader.result)
                        setImage(reader.result)
                        setSavedImageUrl(null)
                    }
                };
            }
        }
    }

    const handleAuthorSearch = (searchTerm: string) => {
        searchAuthors(searchTerm).then(r => {
            setAuthorOptions(r.payload.authors)
        }).catch(e => {
            console.log(e)
            if (e.response) {
                enqueueSnackbar(e.response.data, {
                    variant: 'error',
                    style: { whiteSpace: 'pre-line' }
                })
            }
        })
    }

    const handleDeleteBook = () => {
        if (bookId) {
            deleteBook(bookId)
                .then(() => {
                    enqueueSnackbar("Операцията е изпълнена успешно", {
                        variant: 'success'
                    })
                    history.push("/admin/book")
                })
                .catch(e => {
                    console.log(e)
                    if (e.response) {
                        enqueueSnackbar(e.response.data, {
                            variant: 'error',
                            style: { whiteSpace: 'pre-line' }
                        })
                    }
                })
        }
    }

    const openDeleteDialog = () => {
        setDeleteClicked(true)
    }

    const closeDeleteDialog = () => {
        setDeleteClicked(false)
    }

    useEffect(() => {
        getGenres().then((genres) => {
            setGenres(genres.payload.categories)
            let byId = new Map<number, IGenre>();
            genres.payload.categories.forEach(value => byId.set(value.id, value))
            setGenresById(byId)
        });
        if (match.params.id !== "new") {
            getBook(+match.params.id)
                .then(r => {
                    setBookId(r.payload.book.id)
                    setIsbn(r.payload.book.isbn)
                    setTitle(r.payload.book.title)
                    setListingCount(r.payload.book.listingCount)
                    setMinListingPrice(r.payload.book.minListingPrice)
                    setBookAuthors(r.payload.book.authors)
                    setDescription(r.payload.book.description)
                    setBookGenres(r.payload.book.genres)
                    if (r.payload.book.language) {
                        setLanguage(getLanguageDescription(r.payload.book.language))
                    }
                    setBookCover(getBookCoverDescription(r.payload.book.cover))
                    if(r.payload.book.year) {
                        setYear(String(r.payload.book.year))
                    }
                    if(r.payload.book.price) {
                        setPrice(String(r.payload.book.price))
                    }
                    if(r.payload.book.pages) {
                        setPages(String(r.payload.book.pages))
                    }
                    if (r.payload.book.publisher) {
                        setPublisher(r.payload.book.publisher)
                    }
                    if (r.payload.book.coverImage) {
                        setSavedImageUrl(r.payload.book.coverImage.url)
                    }
                    setIsEdit(true)
                }).catch(e => {
                    console.log(e)
                    if (e.response) {
                        enqueueSnackbar(e.response.data, {
                            variant: 'error',
                            style: { whiteSpace: 'pre-line' }
                        })
                    }
                    history.push("/admin/book")
                });
        }
    }, [getGenres, getBook, match.params.id]);

    const handleSubmit = () => {
        let resource = {
            filename: coverImage,
            contentType: coverImageContentType,
            base64Content: base64
        }
        let form = {
            id: bookId,
            isbn: isbn,
            title: title,
            authors: bookAuthors,
            description: description,
            genres: bookGenres,
            language: getLanguageFromDescription(language),
            cover: getBookCoverFromDescription(bookCover),
            pages: handleInt(pages),
            year: handleInt(year),
            price: handlePrice(),
            publisher: publisher,
            coverImageResource: resource
        }
        saveBook(form)
            .then(() => {
                enqueueSnackbar("Операцията е изпълнена успешно", {
                    variant: 'success'
                })
                history.push("/admin/book")
            })
            .catch(e => {
                console.log(e)
                if (e.response) {
                    enqueueSnackbar(e.response.data, {
                        variant: 'error',
                        style: { whiteSpace: 'pre-line' }
                    })
                }
            })
    };

    const handlePrice = () => {
        if (price) {
            return parseFloat(price)
        } else {
            return null
        }
    }

    const handleInt = (text: string) => {
        if (text) {
            return parseInt(text)
        } else {
            return null
        }
    }

    const handleBookCoverChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setBookCover(event.target.value as string);
    };

    const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setLanguage(event.target.value as string);
    };

    const getLanguageFromDescription = (name: string): string => {
        let map = new Map<string, string>();
        Object.keys(Language).forEach(value => {
                map.set(Language[value as keyof typeof Language], value)
            }
        )
        let value = map.get(name)
        if (value) {
            return value
        } else {
            return ""
        }
    }

    const getBookCoverFromDescription = (name: string): string | null => {
        let map = new Map<string, string>();
        Object.keys(BookCover).forEach(value => {
                map.set(BookCover[value as keyof typeof BookCover], value)
            }
        )
        let value = map.get(name)
        if (value) {
            return value
        } else {
            return null
        }
    }

    const getBookCoverDescription = (bookCover: BookCover): string => {
        let map = new Map<string, string>();
        Object.keys(BookCover).forEach(value => {
                map.set(value, BookCover[value as keyof typeof BookCover])
            }
        )
        let value = map.get(bookCover)
        if (value) {
            return value
        } else {
            return ""
        }
    }

    const getLanguageDescription = (language: Language): string => {
        let map = new Map<string, string>();
        Object.keys(Language).forEach(value => {
                map.set(value, Language[value as keyof typeof Language])
            }
        )
        let value = map.get(language)
        if (value) {
            return value
        } else {
            return ""
        }
    }

    return (
        <Fragment>
            {(loading) && <LinearProgress color="secondary"/>}
            <Typography variant="h6" gutterBottom>
                Book details
            </Typography>
            <Grid container spacing={4}>
                <Grid container item xs={12}>
                    <TextField
                        fullWidth
                        required
                        id="isbn"
                        label="ISBN"
                        value={isbn}
                        onChange={(e) => setIsbn(e.target.value)}
                        placeholder="ISBN"
                        variant="outlined"
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        required
                        id="title"
                        name="Title"
                        label="Заглавие"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid container item xs={12}>
                    <>
                        <InputLabel id="language">Език</InputLabel>
                        <Select
                            labelId="language"
                            id="lang"
                            label={"Език"}
                            placeholder={"Език"}
                            value={language}
                            onChange={handleLanguageChange}
                            fullWidth={true}
                            variant="outlined"
                        >
                            {languages.map(value => {
                                return <MenuItem selected={language === value} value={Language[value as keyof typeof Language]}>
                                    {Language[value as keyof typeof Language]}
                                </MenuItem>
                            })}
                        </Select>
                    </>
                </Grid>
                <Grid container item xs={12}>
                    <>
                        <InputLabel id="bookCover">Корица</InputLabel>
                        <Select
                            labelId="bookCover"
                            id="cover"
                            label={"Корица"}
                            placeholder={"Корица"}
                            value={bookCover}
                            onChange={handleBookCoverChange}
                            fullWidth={true}
                            variant="outlined"
                        >
                            {covers.map(value => {
                                return <MenuItem selected={bookCover === value} value={BookCover[value as keyof typeof BookCover]}>
                                    {BookCover[value as keyof typeof BookCover]}
                                </MenuItem>
                            })}
                        </Select>
                    </>
                </Grid>
                <Grid container item xs={12}>
                    <Autocomplete
                        id="select-authors"
                        value={bookAuthors}
                        loading={loading}
                        multiple
                        fullWidth
                        options={authorOptions}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            return newValue.map((author: IAuthor, index: number, authors: Array<IAuthor>) => {
                                return setBookAuthors(authors)
                            })
                        }}
                        onInputChange={(event, newInputValue) => {
                            handleAuthorSearch(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Автори"
                                placeholder="Автори"
                                required={true}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        required
                        id="description"
                        multiline
                        name="Description"
                        label="Описание"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        variant="outlined"
                        inputProps={{ maxLength: 4096 }}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <Autocomplete
                        value={bookGenres}
                        multiple
                        fullWidth
                        id="tags-standard"
                        options={genres.filter(value => value.parentId !== null)}
                        groupBy={option => {
                            if (option.parentId === null) {
                                return ""
                            } else {
                                let parent = genresById.get(option.parentId)
                                if (parent) {
                                    return parent.title
                                } else {
                                    return ""
                                }
                            }
                        }}
                        getOptionLabel={(option) => option.title}
                        onChange={(event, newValue) => {
                            return newValue.map((value: IGenre, index: number, genres: Array<IGenre>) => {
                                return setBookGenres(genres)
                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Жанрове"
                                variant="outlined"
                                placeholder="Жанрове"
                                required={true}
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        id="price"
                        name="Price"
                        label="Корична цена"
                        type="text"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        id="year"
                        name="Year"
                        label="Година на издаване"
                        type="number"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        id="pages"
                        name="Pages"
                        label="Брой страници"
                        type="number"
                        value={pages}
                        onChange={(e) => setPages(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid container item xs={12}>
                    <TextField
                        id="publisher"
                        name="Publisher"
                        label="Издателство"
                        value={publisher}
                        onChange={(e) => setPublisher(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
                <Grid container item xs={12}>
                    <div>
                        <h5 className="text-center">Изберете снимка за корица на книгата</h5>
                        <label htmlFor="upload-button">
                            {savedImageUrl ? (
                                <img src={savedImageUrl} alt="dummy" width="150" height="200"/>
                            ) : (
                                <>
                                    <img src={image.toString()} alt="dummy" width="150" height="200"/>
                                </>
                            )}
                        </label>
                        <input
                            type="file"
                            id="upload-button"
                            style={{display: "none"}}
                            onChange={imageChange}
                        />
                    </div>
                </Grid>
                {isEdit &&
                    <>
                        <Grid container item xs={12} >
                            <TextField
                                id="listingCount"
                                name="Listings Count"
                                label="Брой обяви"
                                value={listingCount}
                                disabled={true}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <TextField
                                id="minListingPrice"
                                name="Listings Price Starts"
                                label="Най-ниска цена на обява"
                                value={minListingPrice}
                                disabled={true}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                    </>
                }

                <Grid item xs={12} container direction="row" justify="flex-end" alignItems={"flex-end"}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                    >
                        Запази
                    </Button>

                    {bookId &&
                    <>
                        &nbsp; &nbsp; &nbsp;
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => openDeleteDialog()}
                            startIcon={<DeleteIcon />}
                        >
                            Изтрий
                        </Button>

                    </>
                    }
                </Grid>
                <Dialog
                    open={deleteClicked}
                    onClose={closeDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Изтриване на книга?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Изтриването на книга е необратим процес.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDeleteDialog} color="primary">
                            Отказ
                        </Button>
                        <Button onClick={() => handleDeleteBook()} color="primary" autoFocus>
                            Изтрий
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>

        </Fragment>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        loading: store.booksState.loading,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, IBooksActions>) => {
    return {
        getGenres: () => dispatch(getGenresAction()),
        saveBook: (form: IBookForm) => dispatch(saveBook(form)),
        getBook: (id: number) => dispatch(getBook(id)),
        deleteBook: (id: number) => dispatch(deleteBook(id)),
        searchAuthors: (name: string) => dispatch(searchAuthors(name))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookEditor));
