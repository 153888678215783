import {
    IApproveListingForm,
    IBookListingFlatten,
    IBookListingView,
    ListingFilter,
    ListingsPage,
    RejectReason
} from '../../types/types'
import {ThunkAction} from "redux-thunk";
import api from '../api'
import {ActionCreator, Dispatch} from "redux";
import {stringify} from "querystring";

export const GETTING_LISTINGS = "GETTING_LISTINGS";
export const GOT_LISTINGS = "GOT_LISTINGS";
export const GETTING_LISTING = "GETTING_LISTING";
export const GOT_LISTING = "GOT_LISTING";
export const APPROVING_LISTING = "APPROVING_LISTING"
export const APPROVED_LISTING = "APPROVED_LISTING"
export const REJECTING_LISTING = "REJECTING_LISTING"
export const REJECTED_LISTING = "REJECTED_LISTING"
export const DELETING_LISTING = "DELETING_LISTING"
export const DELETED_LISTING = "DELETED_LISTING"
export const CHANGING_LISTING_BOOK = "CHANGING_LISTING_BOOK"
export const CHANGED_LISTING_BOOK = "CHANGED_LISTING_BOOK"

export interface IGettingListingsAction {
    type: typeof GETTING_LISTINGS
}

export interface IGotListingsAction {
    type: typeof GOT_LISTINGS
    payload: {
        listingViews: IBookListingFlatten[]
        count: number
    }
}

export interface IGettingListingAction {
    type: typeof GETTING_LISTING
}

export interface IGotListingAction {
    type: typeof GOT_LISTING
    payload: {
        listing: IBookListingView
    }
}

export interface IApprovingListingAction {
    type: typeof APPROVING_LISTING
}

export interface IApprovedListingAction {
    type: typeof APPROVED_LISTING
    payload: {
        listing: IBookListingView
    }
}

export interface IRejectingListingAction {
    type: typeof REJECTING_LISTING
}

export interface IRejectedListingAction {
    type: typeof REJECTED_LISTING
}

export interface IDeletingListingAction {
    type: typeof DELETING_LISTING
}

export interface IDeletedListingAction {
    type: typeof DELETED_LISTING
}

export interface IChangingListingBook {
    type: typeof CHANGING_LISTING_BOOK
}

export interface IChangedListingBook {
    type: typeof CHANGED_LISTING_BOOK
    payload: {
        listing: IBookListingView
    }
}

export const getListings: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IGotListingsAction>,
    // The type for the data within the last action
    IBookListingView[],
    // The type of the parameter for the nested function
    ListingFilter,
    // The type of the last action to be dispatched
    IGotListingsAction>> = (listingFilter: ListingFilter) => {
    return async (dispatch: Dispatch) => {
        const gettingListingsAction: IGettingListingsAction = {
            type: GETTING_LISTINGS
        };
        let params = `size=${listingFilter.size}&direction=${listingFilter.direction}`
        if (listingFilter.lastSeenId !== null) {
            params = params + `&lastSeenId=${listingFilter.lastSeenId}`
        }
        if (listingFilter.status !== null) {
            params = params + "&status=" + `${listingFilter.status}`.toLowerCase()
        }
        dispatch(gettingListingsAction);
        const axiosResponse = await api.get<ListingsPage>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/listing?${params}`)
        const gotListingsAction: IGotListingsAction = {
            payload: {
                listingViews: axiosResponse.data.listingViews,
                count: axiosResponse.data.count
            },
            type: GOT_LISTINGS
        };
        return dispatch(gotListingsAction);
    };
};

export const getListing: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IGotListingAction>,
    // The type for the data within the last action
    IBookListingView,
    // The type of the parameter for the nested function
    number,
    // The type of the last action to be dispatched
    IGotListingAction>> = (listingId: number) => {
    return async (dispatch: Dispatch) => {

        const gettingListingAction: IGettingListingAction = {
            type: GETTING_LISTING
        };
        dispatch(gettingListingAction);

        const response = await api.get<IBookListingView>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/listing/${listingId}`)
        const gotListingAction: IGotListingAction = {
            payload: {
                listing: response.data
            },
            type: GOT_LISTING
        };
        return dispatch(gotListingAction);
    };
};

export const approveListing: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IApprovedListingAction>,
    // The type for the data within the last action
    IBookListingView,
    // The type of the parameter for the nested function
    IBookListingView,
    // The type of the last action to be dispatched
    IApprovedListingAction>> = (form: IApproveListingForm) => {
    return async (dispatch: Dispatch) => {
        const approvingListingAction: IApprovingListingAction = {
            type: APPROVING_LISTING
        };
        dispatch(approvingListingAction);
        const response = await api.post<IBookListingView>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/listing/pending/approve/${form.listingId}`, form)
        const approvedListingAction: IApprovedListingAction = {
            payload: {
                listing: response.data
            },
            type: APPROVED_LISTING
        };
        return dispatch(approvedListingAction);
    };
};

export const rejectListing: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IRejectedListingAction>,
    // The type for the data within the last action
    IBookListingView,
    // The type of the parameter for the nested function
    IBookListingView,
    // The type of the last action to be dispatched
    IRejectedListingAction>> = (listingId: number, reason: string, message: string) => {
    return async (dispatch: Dispatch) => {
        const rejectingListingAction: IRejectingListingAction = {
            type: REJECTING_LISTING
        };
        dispatch(rejectingListingAction);
        let map = new Map<string, string>();
        Object.keys(RejectReason).forEach(value => {
                map.set(RejectReason[value as keyof typeof RejectReason], value)
            }
        )
        const body = {
            listingId: listingId,
            reason: map.get(reason),
            message: message
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        await api.post<IBookListingView>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/listing/pending/reject/${listingId}`, stringify(body), config)
        const rejectedListingAction: IRejectedListingAction = {
            type: REJECTED_LISTING
        };
        return dispatch(rejectedListingAction);
    };
};

export const deleteListing: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IDeletedListingAction>,
    // The type for the data within the last action
    number,
    // The type of the parameter for the nested function
    number,
    // The type of the last action to be dispatched
    IDeletedListingAction>> = (listingId: number) => {
    return async (dispatch: Dispatch) => {
        const deletingListingAction: IDeletingListingAction = {
            type: DELETING_LISTING
        };
        dispatch(deletingListingAction);

        await api.delete<IBookListingView>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/listing/${listingId}`)
        const deletedListingAction: IDeletedListingAction = {
            type: DELETED_LISTING
        };
        return dispatch(deletedListingAction);
    };
};

export const updateListingBook: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IChangedListingBook>,
    // The type for the data within the last action
    number,
    // The type of the parameter for the nested function
    number,
    // The type of the last action to be dispatched
    IChangedListingBook>> = (listingId: number, isbn: string) => {
    return async (dispatch: Dispatch) => {
        const changingBook: IChangingListingBook = {
            type: CHANGING_LISTING_BOOK
        };
        dispatch(changingBook);

        const response = await api.post<IBookListingView>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/listing/${listingId}/isbn/${isbn}`)
        const bookChanged: IChangedListingBook = {
            type: CHANGED_LISTING_BOOK,
            payload: {
                listing: response.data
            }
        };
        return dispatch(bookChanged);
    };
};

export type IListingsActions =
    | IGettingListingsAction
    | IGotListingsAction
    | IGettingListingAction
    | IGotListingAction
    | IApprovingListingAction
    | IApprovedListingAction
    | IRejectingListingAction
    | IRejectedListingAction
    | IDeletingListingAction
    | IDeletedListingAction
    | IChangingListingBook
    | IChangedListingBook;