import {AuthorsPage, BookFilter, BooksPage, IBook, IBookForm} from '../../types/types'
import {ThunkAction} from "redux-thunk";
import api from '../api'
import {ActionCreator, Dispatch} from "redux";

export const GETTING_BOOKS = "GETTING_BOOKS";
export const GOT_BOOKS = "GOT_BOOKS";
export const GETTING_BOOK = "GETTING_BOOK";
export const GOT_BOOK = "GOT_BOOK";
export const SAVING_BOOK = "SAVING_BOOK";
export const SAVED_BOOK = "SAVED_BOOK";
export const APPROVING_BOOK = "APPROVING_BOOK";
export const APPROVED_BOOK = "APPROVED_BOOK";
export const DELETING_BOOK = "DELETING_BOOK";
export const DELETED_BOOK = "DELETED_BOOK";

export interface IGettingBooksAction {
    type: typeof GETTING_BOOKS
}

export interface IGotBooksAction {
    type: typeof GOT_BOOKS
    payload: {
        books: IBook[]
        count: number
    }
}

export interface IGettingBookAction {
    type: typeof GETTING_BOOK
}

export interface IGotBookAction {
    type: typeof GOT_BOOK
    payload: {
        book: IBook
    }
}

export interface ISavingBookAction {
    type: typeof SAVING_BOOK
}

export interface ISavedBookAction {
    type: typeof SAVED_BOOK,
    payload: {
        book: IBook
    }
}

export interface IApprovingBook {
    type: typeof APPROVING_BOOK
}

export interface IApprovedBook {
    type: typeof APPROVED_BOOK
}

export interface IDeletingBook {
    type: typeof DELETING_BOOK
}

export interface IDeletedBook {
    type: typeof DELETED_BOOK
}

export const getBooks: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IGotBooksAction>,
    // The type for the data within the last action
    IBook[],
    // The type of the parameter for the nested function
    BookFilter,
    // The type of the last action to be dispatched
    IGotBooksAction>> = (bookFilter: BookFilter) => {
    return async (dispatch: Dispatch) => {
        const gettingBooksAction: IGettingBooksAction = {
            type: GETTING_BOOKS
        };

        dispatch(gettingBooksAction);

        const params = new URLSearchParams()
        params.append('direction', String(bookFilter.direction))
        params.append('size', String(bookFilter.size))
        if(bookFilter.lastSeenId) {
            params.append('lastSeenId', String(bookFilter.lastSeenId))
        }
        if (bookFilter.approved !== null) {
            params.append('approved', String(bookFilter.approved))
        }
        if(bookFilter.term) {
            params.append('term', bookFilter.term)
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const axiosResponse = await api.post<BooksPage>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/book/list`, params, config)

        const gotBooksAction: IGotBooksAction = {
            payload: {
                books: axiosResponse.data.books,
                count: axiosResponse.data.count
            },
            type: GOT_BOOKS
        };
        return dispatch(gotBooksAction);
    };
};

export const getBook: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IGotBookAction>,
    // The type for the data within the last action
    IBook,
    // The type of the parameter for the nested function
    number,
    // The type of the last action to be dispatched
    IGotBookAction>> = (bookId: number) => {
    return async (dispatch: Dispatch) => {

        const gettingBooksAction: IGettingBookAction = {
            type: GETTING_BOOK
        };
        dispatch(gettingBooksAction);

        const response = await api.get<IBook>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/book/${bookId}`)
        const gotBookAction: IGotBookAction = {
            payload: {
                book: response.data
            },
            type: GOT_BOOK
        };
        return dispatch(gotBookAction);
    };
};

export const saveBook: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<ISavedBookAction>,
    // The type for the data within the last action
    IBook[],
    // The type of the parameter for the nested function
    IBook,
    // The type of the last action to be dispatched
    ISavedBookAction>> = (book: IBookForm) => {
    return async (dispatch: Dispatch) => {
        const savingBookAction: ISavingBookAction = {
            type: SAVING_BOOK
        };
        dispatch(savingBookAction);
        let response
        if (book.id !== null) {
            response = await api.post<IBook>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/book/${book.id}`, book);
        } else {
            response = await api.post<IBook>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/book`, book);
        }

        const savedBookAction: ISavedBookAction = {
            type: SAVED_BOOK,
            payload: {
                book: response.data
            }
        };
        return dispatch(savedBookAction);
    };
};

export const approveBook: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IApprovedBook>,
    // The type for the data within the last action
    null,
    // The type of the parameter for the nested function
    number,
    // The type of the last action to be dispatched
    IApprovedBook>> = (id: number) => {
    return async (dispatch: Dispatch) => {
        const approving: IApprovedBook = {
            type: APPROVED_BOOK
        };
        dispatch(approving);

        await api.post<AuthorsPage>(`${process.env.REACT_APP_API_BASE_URL}/api/admin/book/approve/${id}`);

        const found: IApprovedBook = {
            type: APPROVED_BOOK
        };
        return dispatch(found);
    };
};

export const deleteBook: ActionCreator<ThunkAction<// The type of the last action to be dispatched - will always be promise<T> for async actions
    Promise<IDeletedBook>,
    // The type for the data within the last action
    null,
    // The type of the parameter for the nested function
    number,
    // The type of the last action to be dispatched
    IDeletedBook>> = (id: number) => {
    return async (dispatch: Dispatch) => {
        const deleting: IDeletingBook = {
            type: DELETING_BOOK
        };
        dispatch(deleting);

        await api.delete(`${process.env.REACT_APP_API_BASE_URL}/api/admin/book/${id}`);

        const deleted: IDeletedBook = {
            type: DELETED_BOOK
        };
        return dispatch(deleted);
    };
};

export type IBooksActions =
    | IGettingBooksAction
    | IGotBooksAction
    | IGettingBookAction
    | IGotBookAction
    | ISavingBookAction
    | ISavedBookAction
    | IApprovingBook
    | IApprovedBook
    | IDeletingBook
    | IDeletedBook;
