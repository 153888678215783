import {Reducer} from "redux";
import {IGenresState} from "../../types/types";
import {
    GETTING_GENRES,
    GOT_GENRES,
    GOT_GENRE_BY_ID,
    IGenreActions,
    SAVED_GENRE,
    SAVING_GENRE
} from "../../actions/genre/actions";

const initialCategoriesState: IGenresState = {
    loading: false
};

// @ts-ignore
export const genresReducer: Reducer<IGenresState, IGenreActions> = (
    state = initialCategoriesState,
    action
) => {

    switch (action.type) {
        case GETTING_GENRES: {
            return {
                ...state,
                loading: true
            };
        }
        case GOT_GENRES: {
            return {
                ...state,
                categories: action.payload.categories,
                loading: false
            };
        }
        case GOT_GENRE_BY_ID: {
            return {
                ...state,
                loading: false
            };
        }
        case SAVING_GENRE: {
            return {
                ...state,
                loading: true
            };
        }

        case SAVED_GENRE: {
            return {
                ...state,
                loading: false
            };
        }
        default:
            return state;
    }
};

